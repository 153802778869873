<template>
  <div class="payment-success">
    <StepsHeader titulo='Divirta-se' step='4' />
        <!-- banner inicial -->
      <div class="container header-image-center bg mt-4">

        <div class="row">
          <div class="col-md-10 mx-auto">
            <div class="text-white text-center py-5 px-4">
              <div>
                <!-- <h2 class="card-title h1-responsive pt-5 mb-1 font-bold"><strong>Seu pedido com código {{insuranceOrderCode}} foi realizado.</strong></h2> -->
                <h2 class="card-title h1-responsive pt-5 mb-1 font-bold"><strong>O seu pagamento está a ser processado.</strong></h2>
                <h4 class="mx-5 mt-4">Em breve receberá um email com a confirmação do pagamento e as condições gerais e particulares do seu seguro.</h4>

                <h4 class="mx-5 mb-5 ">A sua apólice de seguro será emitida e enviada posteriormente por email.</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    <!-- fim banner inicial -->
      <div class="container mt-4">
        <order-resume />
      </div>
  </div>
</template>

<script>
import PublicLayout from '@/layouts/PublicLayout.vue';
import StepsHeader from '@/components/StepsHeader.vue';
import OrderResume from '@/components/OrderResume.vue';

export default {
  name: 'Payment-success',
  components: {
    StepsHeader,
    OrderResume
  },
  created() {
    this.$emit('update:layout', PublicLayout)
  },

  computed: {
    insuranceOrderCode: {
      get () {
        return this.$store.state.insurance.orderCode
      },
      set (value) {
        this.$store.commit('setInsuranceOrderCode', {insuranceOrderCode: value})
      }
    },
  }
}
</script>

<style scoped>
  .bg {
    /* needed to remove the background-image from inline and add to style
    https://github.com/vuejs/vue-loader/issues/646#issuecomment-454959580 */
    background-image: linear-gradient(rgba(0, 0, 0, .35), rgba(0, 0, 0, .25)), url('~@/assets/sucesso.jpg');
  }

  .header-image-center {
      /* //https://stackoverflow.com/a/31147923/3650719 */
      background-position: center bottom;
      background-size:  cover;
    }
</style>