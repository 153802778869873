 <template>
  <div id='banner'>
    <div class="banner-covid">
      <img src="../assets/EA_Selo_SegViagemHR.png" alt="covid" class="img-fluid">
    </div>
    <slick ref="slick" :options="slickOptions">

      <div ref='messageDisplay' class="container-fluid header-image-center bg4 center">
        <div class="center-div m-auto">
            <div class="col-12 col-lg-10">
              <div class="text-white text-center py-5 px-12">
                <div>
                  <img src="../assets/banner4_texto.png" alt="Desconto" class="img-fluid">
                </div>
              </div>
            </div>
        </div>
      </div>

      <div ref='messageDisplay' class="container-fluid header-image-center bg center">
        <div class="center-div m-auto">
            <div class="col-8">
              <div class="text-white text-center py-5 px-12">
                <div>
                  <p class="card-title pt-3 font-bold"><strong>Explore em segurança</strong></p>
                  <h3 class="mx-5 mb-5 title"><strong>Todas as garantias que necessita com o seguro Move da Europ Assistance. Viaje tranquilo e em segurança.</strong></h3>
                  <a class="btn btn-outline-white btn-md"><i class="fa fa-angle-down" aria-hidden="true"></i></a>
                </div>
              </div>
            </div>
        </div>
      </div>

      <div ref='messageDisplay' class="container-fluid header-image-center bg2 center">
        <div class="center-div m-auto">
            <div class="col-8">
              <div class="text-white text-center py-5 px-12">
                <div>
                  <p class="card-title pt-3 font-bold"><strong>Explore com tranquilidade</strong></p>
                  <h3 class="mx-5 mb-md-5 mb-2 title"><strong>Imprevistos acontecem mas com o seguro MOVE Plus, garantimos assistência quando e onde precisar.</strong></h3>
                  <a class="btn btn-outline-white btn-md btn-banner" data-toggle="modal" data-target="#exampleModal">Saiba mais</a>
                </div>
              </div>
            </div>
        </div>
      </div>

      <div ref='messageDisplay' class="container-fluid header-image-center bg3 center">
        <div class="center-div m-auto">
            <div class="col-8">
              <div class="text-white text-center py-5 px-12">
                <div>
                  <p class="card-title pt-3 font-bold"><strong>Explore com Confiança</strong></p>
                  <h3 class="mx-5 mb-5 title"><strong>O seguro de viagem que lhe garante o conforto e a segurança.</strong></h3>
                  <a class="btn btn-outline-white btn-md"><i class="fa fa-angle-down" aria-hidden="true"></i></a>
                </div>
              </div>
            </div>
        </div>
      </div>

    </slick>

    <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
            <h4>Explora com tranquilidade</h4>
            <h2>Asseguramos que estará protegido de múltiplos imprevistos</h2>
            <ul>
              <li>Transporte ou repatriamento após morte de Pessoa Segura</li>
              <li>Pagamento de despesas médicas, cirúrgicas, farmacêuticas e de hospitalização no Estrangeiro por COVID 19</li>
            </ul>
          </div>

        </div>
      </div>
    </div>

  </div>
</template>

<style  scoped>

  .bg {
    /* needed to remove the background-image from inline and add to style
    https://github.com/vuejs/vue-loader/issues/646#issuecomment-454959580 */
    background-image: linear-gradient(rgba(0, 0, 0, .35), rgba(0, 0, 0, .25)), url('~@/assets/banner.jpg');
    display: flex !important;
  }
  .bg2 {
    /* needed to remove the background-image from inline and add to style
    https://github.com/vuejs/vue-loader/issues/646#issuecomment-454959580 */
    background-image: linear-gradient(rgba(0, 0, 0, .35), rgba(0, 0, 0, .25)), url('~@/assets/banner2.jpg');
    display: flex !important;
  }
  .bg3 {
    /* needed to remove the background-image from inline and add to style
    https://github.com/vuejs/vue-loader/issues/646#issuecomment-454959580 */
    background-image: linear-gradient(rgba(0, 0, 0, .35), rgba(0, 0, 0, .25)), url('~@/assets/banner3.jpg');
    display: flex !important;
  }
  .bg4 {
    /* needed to remove the background-image from inline and add to style
    https://github.com/vuejs/vue-loader/issues/646#issuecomment-454959580 */
    background-image: linear-gradient(rgba(0, 0, 0, .35), rgba(0, 0, 0, .25)), url('~@/assets/banner4.png');
    display: flex !important;
  }
  .banner-covid{
    position: absolute;
    z-index: 1;
    right: 5%;
    top: 100px;
  }
  .banner-covid img {
    width: 135px;
    height: auto;
  }

  .header-image-center {
    /* //https://stackoverflow.com/a/31147923/3650719 */
    background-position: center center !important;
    background-size: cover;
    min-height: 500px;
  }

  @media (max-width:500px){
    .banner-covid{
      position: absolute;
      z-index: 1;
      right: 5%;
      top: 140px;
    }
    .banner-covid img {
      width: 110px;
      height: auto;
    }

    .header-image-center {
      padding-top: 60px;
      height: 550px;
    }
  }

  .btn-banner{
    background: #ffff;
    color: #00239c !important;
    padding: 10px 25px;
    font-size: 16px;
    font-weight: 600;
  }
  .btn-banner:hover{
    background: #d2daf7;
  }

  #banner .modal{
    background: #00205bd4;
  }
  #banner .modal-body{
    padding: 70px;
  }
  #banner .modal-body .close{
    position: absolute;
    width: 56px;
    height: 56px;
    border-radius: 50%;
    background:#f8f8f9;
    top: 25px;
    right: 25px;
  }
  #banner .modal-body .close span{
    color: #cbcfd1;
  }
  #banner .modal-body h4{
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
  }

  #banner .modal-body h2{
    font-size: 32px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
  }

  #banner .modal-body ul{
    list-style-type: none;
    margin-top: 40px;
  }
  #banner .modal-body ul li{
    padding: 15px;
    padding-left: 60px;
    position: relative;
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color: #000000;

    border-top: solid 1px #c1c6c8;
    border-bottom: solid 1px #c1c6c8;
  }

  #banner .modal-body ul li::before{
    content: ' ';
    background: url('~@/assets/check.png');
    background-size: 30px;
    background-repeat: no-repeat;
    width: 30px;
    height: 30px;
    position: absolute;
    left: 0;
    top: 33%;
  }
  @media (max-width:500px){

    #banner .modal-body{
      padding: 10px;
    }
    #banner .modal-body ul li{
      font-size: 14px;
    }
    #banner .modal-body h2{
      font-size: 16px;
    }
  }
</style>

<script>

  export default {
    data() {
      return {
        slickOptions: {
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          arrows: true,
          auto:false,
          responsive: [
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
                dots: true,
              }
            },
          ]
        },
      }
    },
    methods: {
        next() {
            this.$refs.slick.next();
        },

        prev() {
            this.$refs.slick.prev();
        },

        reInit() {
            // Helpful if you have to deal with v-for to update dynamic lists
            this.$nextTick(() => {
                this.$refs.slick.reSlick();
            });
        },
    },
  }
</script>