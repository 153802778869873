import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'vue-select/dist/vue-select.css'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


import Vuex from 'vuex'
import App from './App.vue'
import VueGtag from "vue-gtag"
import ToggleButton from 'vue-js-toggle-button'
import router from './router'
import vSelect from 'vue-select'
import Datepicker from 'vuejs-datepicker'
import moment from 'moment'
import store from './store'
import Vuelidate from 'vuelidate'
// import VueCookies from "vue-cookies"
import Slick from 'vue-slick';
import VeeValidate from 'vee-validate';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
  faHome,
  faUser,
  faUserPlus,
  faSignInAlt,
  faSignOutAlt,
  faAngleDown
} from '@fortawesome/free-solid-svg-icons';
library.add(faHome, faUser, faUserPlus, faSignInAlt, faSignOutAlt, faAngleDown);



Vue.use(Vuex)
Vue.use(BootstrapVue)
Vue.use(ToggleButton)
Vue.use(Vuelidate)
// Vue.use(VueCookies)
Vue.use(VeeValidate, {
  errorBagName: 'vErrors'
});
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('Datepicker', Datepicker)
Vue.component('v-select', vSelect)
Vue.component('Slick', Slick)
Vue.config.productionTip = false
Vue.filter('formatDate', function(value) {
  if (value) {
    return moment(String(value)).format('DD/MM/YYYY')
  }
});
Vue.use(VueGtag, {
  config: { id: "UA-159686956-1" },
  onReady() {
    // Definindo as preferências de consentimento
    window.gtag('consent', 'default', {
      'ad_storage': 'granted',
      'ad_user_data': 'granted',
      'ad_personalization': 'granted',
      'analytics_storage': 'granted'
    });
  }
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
