import axios from "axios";

let baseURL

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  baseURL = "http://localhost:8080/api"
} else if (process.env.NODE_ENV === 'test'){
  baseURL = "https://staging.api.segurosviagem.pt/api"
}else {
  baseURL = "https://api.segurosviagem.pt/api"
}

export default axios.create({
  baseURL: baseURL,
  headers: {
    "Content-type": "application/json",
  }
});