<template>
  <div class="contact">
    <b-container>
      <h1>Fale connosco</h1>
      <ContactForm />
      <ContactInfoForm />
    </b-container>
  </div>
</template>

<script>
  import ContactForm from '@/components/ContactForm.vue'
  import ContactInfoForm from '@/components/ContactInfoForm.vue'
  import PublicLayout from '@/layouts/PublicLayout.vue';

  export default {
    name: 'fale-connosco',
    created() {
      this.$emit('update:layout', PublicLayout)
    },
    components: {
      ContactForm,
      ContactInfoForm
    }
  }
</script>
