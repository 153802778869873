<template>
  <div class="payment-success">
    <StepsHeader titulo="MultiBanco" step="3" />
    <!-- banner inicial -->
    <div class="container mt-4">
      <div class="row">
        <div class="col-md-10 mx-auto">
          <div>
            <h2 class="card-title text-center h1-responsive">Efectue o pagamento para confirmar a aquisição do seguro</h2>
            <p class=" text-center text-center">Realize o pagamento em até 30 minutos para confirmar e receber sua apólice.</p>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6 col-lg-5 mx-auto">
          <div class="card border-primary">
            <img
              class="card-img-top mt-4 text-center mx-auto"
              src="@/assets/logo-multibanco-vertical.png"
              style="width: 300px;"
            />
            <div class="card-body">
              <h5 class="card-title text-center">Pagamento por Multibanco</h5>
            </div>
            <ul class="list-group list-group-flush">
              <li class="list-group-item">Entidade: {{easyPayEntity}}</li>
              <li class="list-group-item">Referência: {{easyPayReference}}</li>
              <li class="list-group-item">Valor: {{easyPayPrice}} €</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-8 mx-auto">
          <div>
            <p
              class=" text-center text-center"
            >Pagamento válido até: {{easyPayExpirationTime}} </p>
            <p class=" text-center">
              A apólice do seguro será emitida e enviada por email após a confirmação do pagamento.
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- fim banner inicial -->
    <div class="container mt-4">
      <order-resume />
    </div>
  </div>
</template>

<script>
import PublicLayout from "@/layouts/PublicLayout.vue";
import StepsHeader from "@/components/StepsHeader.vue";
import EasyPayService from '../services/EasyPayService';
import OrderResume from '@/components/OrderResume.vue';

export default {
  name: "Payment-success",
  components: {
    StepsHeader,
    OrderResume
  },
  created() {
    this.$emit("update:layout", PublicLayout);
  },
  mounted() {    
    this.runCheckEvery10s();
  },
  data() {
    return {
      multibancoPaymentStatus: '',
      recurringCheck: null,
    }
  },
  computed: {
    insuranceOrderCode: {
      get() {
        return this.$store.state.insurance.orderCode;
      },
      set(value) {
        this.$store.commit("setInsuranceOrderCode", {
          insuranceOrderCode: value
        });
      }
    },
    easyPayEntity: {
      get() {
        return this.$store.state.insurance.easyPayEntity;
      },
      set(value) {
        this.$store.commit("setEasyPayEntity", { easyPayEntity: value });
      }
    },
    easyPayReference: {
      get() {
        return this.$store.state.insurance.easyPayReference;
      },
      set(value) {
        this.$store.commit("setEasyPayReference", { easyPayReference: value });
      }
    },
    easyPayPrice: {
      get() {
        return this.$store.state.insurance.easyPayPrice;
      },
      set(value) {
        this.$store.commit("setEasyPayPrice", { easyPayPrice: value });
      }
    },
    easyPayExpirationTime: {
      get () {
        return this.$store.state.insurance.easyPayExpirationTime
      },
      set (value) {
        this.$store.commit('setEasyPayExpirationTime', {easyPayExpirationTime: value})
      }
    },
  },
  methods: {
    checkMultibancoStatus(){
      var data = {
        order_code: this.insuranceOrderCode 
      };
      EasyPayService.checkPaymentStatus(data)
      .then(response => {
        this.multibancoPaymentStatus = response.payment_status
      })
    },
    runCheckEvery10s(){
      this.recurringCheck = setInterval(() => {
        this.checkMultibancoStatus()
      }, 10000)
    }
  },
  //  Para que o setInterval para de rodar ao sair da pagina
  // https://renatello.com/vue-js-polling-using-setinterval/
  beforeDestroy () {
    clearInterval(this.recurringCheck)
  }
};
</script>

<style scoped>
</style>