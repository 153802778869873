<template>
  <div>
    <StepsHeader titulo="Faça a sua escolha" step="1" />
    <b-alert v-model="showEmailSentAlert" variant="info" dismissible>
      {{ this.emailSenderMessage }}
    </b-alert>
    <loading :active.sync="isLoading" :is-full-page="fullPage"> </loading>
    <div class="container-fluid" style="background: #f8f8f9">
      <div class="container">
        <div class="row justify-content-md-center">
          <div class="col-lg-4 col-md-5 col-sm-6">
            <div class="card text-center">
              <div class="card-body">
                <h5 class="card-title title-plans text-primary mb-5">MOVE</h5>
                <p class="card-text font-weight-bolder" style="font-size: 10px">
                  PRÉMIO FINAL
                </p>
                <p class="card-text font-weight-bolder" style="font-size: 70px">
                  {{ this.moveFinalPrice }} €
                </p>
                <button
                  @click="setPlan('MOVE', moveFinalPrice), navigateNext()"
                  class="btn btn-primary mt-5"
                >
                  Comprar
                </button>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-5 col-sm-6">
            <div class="card text-center">
              <div class="card-body">
                <h5 class="card-title title-plans color-orange mb-5">
                  MOVE Plus
                </h5>
                <p class="card-text font-weight-bolder" style="font-size: 10px">
                  PRÉMIO FINAL
                </p>
                <p class="card-text font-weight-bolder" style="font-size: 70px">
                  {{ this.movePlusFinalPrice }} €
                </p>
                <button
                  @click="setPlan('MOVE Plus', movePlusFinalPrice), navigateNext()"
                  class="btn btn-primary mt-5 btn-orange"
                >
                  Comprar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- container com tabela de comparacao e caixa de email -->
    <div class="container-lg mt-5">
      <div class="row justify-content-md-center d-flex">
        <!-- inicio coluna da direita  -->
        <div class="col-md-10 col-lg-8">
          <!-- inicio tabela de comparacao -->
          <div class="container comparasion-table">
            <div class="row">
              <div class="col-md-12 my-4 mx-auto">
                <h2 class="text-left">
                  Está coberto por:
                  <img
                    src="../assets/images/logo-europ-assistance-table.png"
                    class="float-right logo"
                  />
                </h2>

                <div class="row">
                  <div class="col-lg-12">
                    <div class="">
                      <div class="table mt-4">
                        <tbody class="table-responsive">
                          <tr>
                            <td class="w-75" style="border-width: 0"></td>
                            <!-- <td class="text-primary background-gray" style="border-width: 0;"><strong>MOVE</strong></td>
                                <td class="color-orange background-gray" style="border-width: 0;"><strong>MOVE Plus</strong></td> -->
                          </tr>
                          <tr>
                            <td class="w-75 first-line-table">
                              Descrição completa da cobertura
                            </td>
                            <td
                              class="text-primary background-table-gray align-middle title-table text-center"
                              style="width: 25%; min-width: 151px"
                            >
                              <strong>MOVE</strong>
                            </td>
                            <td
                              class="color-orange background-table-yellow align-middle title-table text-center"
                              style="width: 25%; min-width: 151px"
                            >
                              <strong>MOVE Plus</strong>
                            </td>
                            <!-- <td class="background-gray"><button class="btn btn-primary btn-dark-blue">Ver</button></td>
                                <td class="background-gray"><button class="btn btn-primary btn-dark-blue">Ver</button></td> -->
                          </tr>
                          <tr>
                            <td
                              colspan="3"
                              class="w-75"
                              style="font-size: 14px"
                            >
                              <br />
                              Não dispensa a consulta de informação contratual e
                              pré-contratual legalmente exigida. Europ
                              Assistance S.A.- Sucursal em Portugal.
                            </td>
                          </tr>
                          <tr>
                            <td
                              class="w-75 insurance-item"
                              v-b-toggle.assistencia
                            >
                              › Assistência em Viagem
                              <!-- Element to collapse -->
                              <b-collapse id="assistencia">
                                <b-card class="toggle">
                                  A Europ Assistance coloca à sua disposição os
                                  melhores meios de assistência em viagem em
                                  caso de acidente ou doença súbita. Garantimos
                                  um acompanhamento médico personalizado,
                                  24h/365 dias, de acordo com o previsto nas
                                  Condições da Apólice.
                                </b-card>
                              </b-collapse>
                            </td>

                            <td class="background-table-gray comparison-item">
                              Base
                            </td>
                            <td class="background-table-yellow comparison-item">
                              Plus
                            </td>
                          </tr>
                          <tr>
                            <td class="w-75 insurance-item" v-b-toggle.despesas>
                              › Despesas médicas
                              <!-- Element to collapse -->
                              <b-collapse id="despesas">
                                <b-card class="toggle">
                                  Em caso de acidente ou doença súbita,
                                  garantimos as despesas médicas, cirúrgicas,
                                  farmacêuticas e de hospitalização no
                                  estrangeiro, até aos limites contratados e de
                                  acordo com o previsto nas Condições da
                                  Apólice.
                                </b-card>
                              </b-collapse>
                            </td>

                            <td class="background-table-gray comparison-item">
                              30.000 €
                            </td>
                            <td class="background-table-yellow comparison-item">
                              60.000 €
                            </td>
                          </tr>
                          <tr>
                            <td
                              class="w-75 insurance-item"
                              v-b-toggle.cancelamento
                            >
                              › Cancelamento ou interrupção de viagem
                              <b-collapse id="cancelamento">
                                <b-card class="toggle">
                                  Se por motivo de força maior for obrigado a
                                  cancelar ou interromper a sua viagem,
                                  garantimos o reembolso dos gastos
                                  irrecuperáveis de alojamento e transporte, até
                                  aos limites contratados e de acordo com o
                                  previsto nas Condições da Apólice.
                                </b-card>
                              </b-collapse>
                            </td>
                            <td class="background-table-gray comparison-item">
                              1.000 €
                            </td>
                            <td class="background-table-yellow comparison-item">
                              2.000 €
                            </td>
                          </tr>
                          <tr>
                            <td class="w-75 insurance-item" v-b-toggle.bagagem>
                              › Bagagem
                              <!-- Element to collapse -->
                              <b-collapse id="bagagem">
                                <b-card class="toggle">
                                  O Serviço de assistência garante uma
                                  indemnização, em caso de perda, dano, furto ou
                                  roubo da sua bagagem pessoal, que ainda
                                  subsista depois do valor assumido pela empresa
                                  transportadora, até aos limites contratados e
                                  de acordo com o previsto nas Condições da
                                  Apólice.
                                </b-card>
                              </b-collapse>
                            </td>
                            <td class="background-table-gray comparison-item">
                              750 €
                            </td>
                            <td class="background-table-yellow comparison-item">
                              1.500 €
                            </td>
                          </tr>
                          <tr>
                            <td
                              class="w-75 insurance-item"
                              v-b-toggle.collapse-perda-de-ligacao
                            >
                              › Perda de ligações aéreas
                              <b-collapse id="collapse-perda-de-ligacao">
                                <b-card class="toggle">
                                  Em caso de perda de ligação entre dois voos
                                  por atraso na chegada do avião, garantimos o
                                  transporte e alojamento até ao próximo voo, de
                                  acordo com o previsto nas Condições da
                                  Apólice.
                                </b-card>
                              </b-collapse>
                            </td>
                            <td class="background-table-gray comparison-item">
                              ✓
                            </td>
                            <td class="background-table-yellow comparison-item">
                              ✓
                            </td>
                          </tr>
                          <tr>
                            <td
                              class="w-75 insurance-item"
                              v-b-toggle.collapse-atraso-de-voo
                            >
                              › Atraso de voo
                              <b-collapse id="collapse-atraso-de-voo">
                                <b-card class="toggle">
                                  No caso de atraso superior a 12h no voo de
                                  regresso a casa, garantimos o transporte e
                                  alojamento, até ao próximo voo de acordo com o
                                  previsto nas Condições da Apólice.
                                </b-card>
                              </b-collapse>
                            </td>

                            <td class="background-table-gray comparison-item">
                              ✓
                            </td>
                            <td class="background-table-yellow comparison-item">
                              ✓
                            </td>
                          </tr>
                          <!--
                              <tr v-if="insurancePersonalAccidents">
                                <td class="w-75 insurance-item" v-b-toggle.collapse-acidentes>› Acidentes pessoais em viagem
                                  <b-collapse id="collapse-acidentes">
                                    <b-card class="toggle">
                                      Garantimos as indemnizações devidas em caso de Morte, Invalidez Permanente e Incapacidade Temporária Absoluta no decurso da viagem, até aos limites contratados e de acordo com o previsto nas condições da Apólice.
                                    </b-card>
                                  </b-collapse>
                                </td>
                                <td class="background-table-gray comparison-item">25.000 €</td>
                                <td class="background-table-yellow comparison-item">100.000 €</td>
                                <button class="btn btn-danger  m-2" @click="setInsurancePersonalAccidents(false)" style="border-radius: 12px;"><strong> x </strong></button>
                              </tr>
                              -->

                          <tr v-if="insuranceCivilResponsability && insurancePeople74YearsQuantity === 0">
                            <td
                              class="w-75 insurance-item"
                              v-b-toggle.collapse-responsabilidade
                            >
                              › Acidentes pessoais em viagem e Responsabilidade
                              civil
                              <b-collapse id="collapse-responsabilidade">
                                <b-card class="toggle">
                                  <p>
                                    <b>Acidentes Pessoais em Viagem</b>
                                    Garantimos as indemnizações devidas em caso
                                    de Morte, Invalidez Permanente e
                                    Incapacidade por internamento hospitalar no decurso
                                    da viagem, até aos limites contratados e de
                                    acordo com o previsto nas condições da
                                    Apólice.
                                  </p>
                                  <p>
                                    <b>Responsabilidade Civil</b>
                                    Garantimos o pagamento dos danos
                                    involuntariamente causados a terceiros que,
                                    nos termos da lei civil, sejam exigíveis à
                                    Pessoa Segura durante a viagem, até aos
                                    limites contratados e de acordo com o
                                    previsto nas Condições da Apólice.
                                  </p>
                                </b-card>
                              </b-collapse>
                            </td>
                            <td class="background-table-gray comparison-item">
                              25.000 € AP <br />
                              10.000 € RC
                            </td>
                            <td class="background-table-yellow comparison-item">
                              100.000 € AP <br />
                              15.000 € RC
                            </td>
                            <button
                              class="btn btn-danger m-2"
                              @click="setInsuranceCivilResponsability(false)"
                              style="border-radius: 12px"
                            >
                              <strong> x </strong>
                            </button>
                          </tr>

                          <!-- Cobertura COVID -->
                          <tr v-if="totalDay <= 90 && insuranceCovid">
                            <td
                              class="w-75 insurance-item"
                              @click.prevent="covidCobertura()"
                              style="cursor: pointer"
                            >
                              › Proteção Assistência Covid (Até ao máximo de 90 Dias)
                            </td>

                            <td class="background-table-gray comparison-item">
                              ✓
                            </td>
                            <td class="background-table-yellow comparison-item">
                              ✓
                            </td>
                            <button
                              class="btn btn-danger m-2"
                              @click="setInsuranceCovid(false)"
                              style="border-radius: 12px"
                            >
                              <strong> x </strong>
                            </button>
                          </tr>
                          <tr
                            v-if="
                              totalDay <= 90 &&
                              viewCoberturaCovid == true &&
                              insuranceCovid
                            "
                          >
                            <td class="w-75 insurance-item-coberture">
                              Pagamento de despesas médicas, cirúrgicas,
                              farmacêuticas e de hospitalização no Estrangeiro
                              por COVID 19 (Até 90 Dias após o início da Apólice)
                            </td>

                            <td class="background-table-gray comparison-item">
                              20 000€
                            </td>
                            <td class="background-table-yellow comparison-item">
                              20 000€
                            </td>
                          </tr>
                          <tr
                            v-if="
                              totalDay <= 90 &&
                              viewCoberturaCovid == true &&
                              insuranceCovid
                            "
                          >
                            <td class="w-75 insurance-item-coberture">
                              Consulta Médica Online
                            </td>

                            <td class="background-table-gray comparison-item">
                              2 Consultas
                            </td>
                            <td class="background-table-yellow comparison-item">
                              2 Consultas
                            </td>
                          </tr>
                          <tr
                            v-if="
                              totalDay <= 90 &&
                              viewCoberturaCovid == true &&
                              insuranceCovid
                            "
                          >
                            <td class="w-75 insurance-item-coberture">
                              Aconselhamento Médico
                            </td>

                            <td class="background-table-gray comparison-item">
                              2 Aconselhamentos Médicos
                            </td>
                            <td class="background-table-yellow comparison-item">
                              2 Aconselhamentos Médicos
                            </td>
                          </tr>
                          <tr
                            v-if="
                              totalDay <= 90 &&
                              viewCoberturaCovid == true &&
                              insuranceCovid
                            "
                          >
                            <td class="w-75 insurance-item-coberture">
                              Aconselhamento Psicológico
                            </td>

                            <td class="background-table-gray comparison-item">
                              1 aconselhamento
                            </td>
                            <td class="background-table-yellow comparison-item">
                              1 aconselhamento
                            </td>
                          </tr>
                          <tr
                            v-if="
                              totalDay <= 90 &&
                              viewCoberturaCovid == true &&
                              insuranceCovid
                            "
                          >
                            <td class="w-75 insurance-item-coberture">
                              Transporte ou repatriamento após morte de Pessoa
                              Segura
                            </td>

                            <td class="background-table-gray comparison-item">
                              Transporte Ilimitado <br />
                              Urna: 750 € <br />
                              Estadia: 50 €/ Dia - Máximo: 3 dias
                            </td>
                            <td class="background-table-yellow comparison-item">
                              Transporte Ilimitado <br />
                              Urna: 750 € <br />
                              Estadia: 50 €/ Dia - Máximo: 3 dias
                            </td>
                          </tr>
                          <tr
                            v-if="
                              totalDay <= 90 &&
                              this.paises === 'Portugal' &&
                              viewCoberturaCovid == true &&
                              insuranceCovid
                            "
                          >
                            <td class="w-75 insurance-item-coberture">
                              Transporte em caso de Interrupção da viagem de
                              regresso
                            </td>

                            <td class="background-table-gray comparison-item">
                              500 €
                            </td>
                            <td class="background-table-yellow comparison-item">
                              500 €
                            </td>
                          </tr>
                          <tr
                            v-if="
                              totalDay <= 90 &&
                              this.paises !== 'Portugal' &&
                              viewCoberturaCovid == true &&
                              insuranceCovid
                            "
                          >
                            <td class="w-75 insurance-item-coberture">
                              Transporte em caso de Interrupção da viagem de
                              regresso
                            </td>

                            <td class="background-table-gray comparison-item">
                              500 € (em Portugal)
                              1.000 € (no Estrangeiro)
                            </td>
                            <td class="background-table-yellow comparison-item">
                              500 € (em Portugal)
                              1.000 € (no Estrangeiro)
                            </td>
                          </tr>
                          <tr
                            v-if="
                              totalDay <= 90 &&
                              viewCoberturaCovid == true &&
                              insuranceCovid
                            "
                          >
                            <td class="w-75 insurance-item-coberture">
                              Cancelamento Antecipado de Viagem Segura
                            </td>

                            <td class="background-table-gray comparison-item">
                              Valor máximo indemnizável: 2.000€. Período de
                              Carência de 15 dias
                            </td>
                            <td class="background-table-yellow comparison-item">
                              Valor máximo indemnizável. 2000€. Período de
                              Carência de 15 dias
                            </td>
                          </tr>
                          <tr
                            v-if="
                              totalDay <= 90 &&
                              viewCoberturaCovid == true &&
                              insuranceCovid
                            "
                          >
                            <td class="w-75 insurance-item-coberture">
                              Interrupção de Viagem Segura
                            </td>

                            <td class="background-table-gray comparison-item">
                              Valor máximo de indemnização: 1000€
                            </td>
                            <td class="background-table-yellow comparison-item">
                              Valor máximo de indemnização: 1000€
                            </td>
                          </tr>
                          <tr
                            v-if="
                              totalDay <= 90 &&
                              viewCoberturaCovid == true &&
                              insuranceCovid
                            "
                          >
                            <td class="w-75 insurance-item-coberture">
                              Linha Informativa Covid-19
                            </td>

                            <td class="background-table-gray comparison-item">
                              Acesso ao serviço ilimitado
                            </td>
                            <td class="background-table-yellow comparison-item">
                              Acesso ao serviço ilimitado
                            </td>
                          </tr>
                          <!-- fim Cobertura COVID -->

                          <tr
                            v-if="
                              !insurancePersonalAccidents ||
                              !insuranceCivilResponsability ||
                              !insuranceCovid
                            "
                          >
                            <td
                              colspan="3"
                              class="w-75"
                              style="font-size: 14px"
                            >
                              <h3 class="w-75 first-line-table">
                                Adicionar Coberturas:
                              </h3>
                            </td>
                          </tr>

                          <!--
                              <tr v-if="!insurancePersonalAccidents">
                                <td class="w-75 insurance-item" v-b-toggle.collapse-acidentes>› Acidentes pessoais em viagem
                                  <b-collapse id="collapse-acidentes">
                                    <b-card class="toggle">
                                      Garantimos as indemnizações devidas em caso de Morte, Invalidez Permanente e Incapacidade Temporária Absoluta no decurso da viagem, até aos limites contratados e de acordo com o previsto nas condições da Apólice.
                                    </b-card>
                                  </b-collapse>
                                </td>
                                <td class="background-table-gray comparison-item">25.000 €</td>
                                <td class="background-table-yellow comparison-item">100.000 €</td>
                                <button class="btn btn-primary btn-dark-blue m-2" @click="setInsurancePersonalAccidents(true)" style="border-radius: 12px;"><strong> + </strong></button>
                              </tr>
                              -->

                          <tr v-if="!insuranceCivilResponsability && insurancePeople74YearsQuantity === 0">
                            <td
                              class="w-75 insurance-item"
                              v-b-toggle.collapse-responsabilidade
                            >
                              › Acidentes pessoais em viagem e Responsabilidade
                              civil
                              <b-collapse id="collapse-responsabilidade">
                                <b-card class="toggle">
                                  <p>
                                    <b>Acidentes Pessoais em Viagem</b>
                                    Garantimos as indemnizações devidas em caso
                                    de Morte, Invalidez Permanente e
                                    Incapacidade por internamento hospitalar no decurso
                                    da viagem, até aos limites contratados e de
                                    acordo com o previsto nas condições da
                                    Apólice.
                                  </p>
                                  <p>
                                    <b>Responsabilidade Civil</b>
                                    Garantimos o pagamento dos danos
                                    involuntariamente causados a terceiros que,
                                    nos termos da lei civil, sejam exigíveis à
                                    Pessoa Segura durante a viagem, até aos
                                    limites contratados e de acordo com o
                                    previsto nas Condições da Apólice.
                                  </p>
                                </b-card>
                              </b-collapse>
                            </td>
                            <td class="background-table-gray comparison-item">
                              25.000 € AP <br />
                              10.000 € RC
                            </td>
                            <td class="background-table-yellow comparison-item">
                              100.000 € AP <br />
                              15.000 € RC
                            </td>

                            <button
                              class="btn btn-primary btn-dark-blue m-2"
                              @click="setInsuranceCivilResponsability(true)"
                              style="border-radius: 12px"
                            >
                              <strong> + </strong>
                            </button>
                          </tr>

                          <!-- Cobertura COVID -->
                          <tr v-if="totalDay <= 90 && !insuranceCovid">
                            <td
                              class="w-75 insurance-item"
                              @click.prevent="covidCobertura()"
                              style="cursor: pointer"
                            >
                              › Proteção Assistência Covid (Até ao máximo de 90 Dias)
                            </td>

                            <td class="background-table-gray comparison-item">
                              ✓
                            </td>
                            <td class="background-table-yellow comparison-item">
                              ✓
                            </td>
                            <button
                              class="btn btn-primary btn-dark-blue m-2"
                              @click="setInsuranceCovid(true)"
                              style="border-radius: 12px"
                            >
                              <strong> + </strong>
                            </button>
                          </tr>
                          <tr
                            v-if="
                              totalDay <= 90 &&
                              viewCoberturaCovid == true &&
                              !insuranceCovid
                            "
                          >
                            <td class="w-75 insurance-item-coberture">
                              Pagamento de despesas médicas, cirúrgicas,
                              farmacêuticas e de hospitalização no Estrangeiro
                              por COVID 19 (Até 90 Dias após o início da Apólice)
                            </td>

                            <td class="background-table-gray comparison-item">
                              20 000€
                            </td>
                            <td class="background-table-yellow comparison-item">
                              20 000€
                            </td>
                          </tr>
                          <tr
                            v-if="
                              totalDay <= 90 &&
                              viewCoberturaCovid == true &&
                              !insuranceCovid
                            "
                          >
                            <td class="w-75 insurance-item-coberture">
                              Consulta Médica Online
                            </td>

                            <td class="background-table-gray comparison-item">
                              2 Consultas
                            </td>
                            <td class="background-table-yellow comparison-item">
                              2 Consultas
                            </td>
                          </tr>
                          <tr
                            v-if="
                              totalDay <= 90 &&
                              viewCoberturaCovid == true &&
                              !insuranceCovid
                            "
                          >
                            <td class="w-75 insurance-item-coberture">
                              Aconselhamento Médico
                            </td>

                            <td class="background-table-gray comparison-item">
                              2 Aconselhamentos Médicos
                            </td>
                            <td class="background-table-yellow comparison-item">
                              2 Aconselhamentos Médicos
                            </td>
                          </tr>
                          <tr
                            v-if="
                              totalDay <= 90 &&
                              viewCoberturaCovid == true &&
                              !insuranceCovid
                            "
                          >
                            <td class="w-75 insurance-item-coberture">
                              Aconselhamento Psicológico
                            </td>

                            <td class="background-table-gray comparison-item">
                              1 aconselhamento
                            </td>
                            <td class="background-table-yellow comparison-item">
                              1 aconselhamento
                            </td>
                          </tr>
                          <tr
                            v-if="
                              totalDay <= 90 &&
                              viewCoberturaCovid == true &&
                              !insuranceCovid
                            "
                          >
                            <td class="w-75 insurance-item-coberture">
                              Transporte ou repatriamento após morte de Pessoa
                              Segura
                            </td>

                            <td class="background-table-gray comparison-item">
                              Transporte Ilimitado <br />
                              Urna: 750 € <br />
                              Estadia: 50 € / Dia - Máximo: 3 dias
                            </td>
                            <td class="background-table-yellow comparison-item">
                              Transporte Ilimitado <br />
                              Urna: 750 €<br />
                              Estadia: 50 € / Dia - Máximo: 3 dias
                            </td>
                          </tr>
                          <tr
                            v-if="
                              totalDay <= 90 &&
                              this.paises === 'Portugal' &&
                              viewCoberturaCovid == true &&
                              !insuranceCovid
                            "
                          >
                            <td class="w-75 insurance-item-coberture">
                              Transporte em caso de Interrupção da viagem de
                              regresso
                            </td>

                            <td class="background-table-gray comparison-item">
                              500 €
                            </td>
                            <td class="background-table-yellow comparison-item">
                              500 €
                            </td>
                          </tr>
                          <tr
                            v-if="
                              totalDay <= 90 &&
                              this.paises !== 'Portugal' &&
                              viewCoberturaCovid == true &&
                              !insuranceCovid
                            "
                          >
                            <td class="w-75 insurance-item-coberture">
                              Transporte em caso de Interrupção da viagem de
                              regresso
                            </td>

                            <td class="background-table-gray comparison-item">
                              500 € (em Portugal)
                              1.000 € (no Estrangeiro)
                            </td>
                            <td class="background-table-yellow comparison-item">
                              500 € (em Portugal)
                              1.000 € (no Estrangeiro)
                            </td>
                          </tr>
                          <tr
                            v-if="
                              totalDay <= 90 &&
                              viewCoberturaCovid == true &&
                              !insuranceCovid
                            "
                          >
                            <td class="w-75 insurance-item-coberture">
                              Cancelamento Antecipado de Viagem Segura
                            </td>

                            <td class="background-table-gray comparison-item">
                              Valor máximo indemnizável: 2.000€. Período de
                              Carência de 15 dias
                            </td>
                            <td class="background-table-yellow comparison-item">
                              Valor máximo indemnizável. 2000€. Período de
                              Carência de 15 dias
                            </td>
                          </tr>
                          <tr
                            v-if="
                              totalDay <= 90 &&
                              viewCoberturaCovid == true &&
                              !insuranceCovid
                            "
                          >
                            <td class="w-75 insurance-item-coberture">
                              Interrupção de Viagem Segura
                            </td>

                            <td class="background-table-gray comparison-item">
                              Valor máximo de indemnização: 1000€
                            </td>
                            <td class="background-table-yellow comparison-item">
                              Valor máximo de indemnização: 1000€
                            </td>
                          </tr>
                          <tr
                            v-if="
                              totalDay <= 90 &&
                              viewCoberturaCovid == true &&
                              !insuranceCovid
                            "
                          >
                            <td class="w-75 insurance-item-coberture">
                              Linha Informativa Covid-19
                            </td>

                            <td class="background-table-gray comparison-item">
                              Acesso ao serviço ilimitado
                            </td>
                            <td class="background-table-yellow comparison-item">
                              Acesso ao serviço ilimitado
                            </td>
                          </tr>
                          <!-- fim Cobertura COVID -->

                          <tr>
                            <td class="w-75 first-line-table">Prémio Final</td>
                            <td class="background-table-gray comparison-price">
                              {{ moveFinalPrice }} €
                            </td>
                            <td
                              class="background-table-yellow comparison-price"
                            >
                              {{ movePlusFinalPrice }} €
                            </td>
                          </tr>
                          <tr>
                            <td class="w-75" style="border-width: 0"></td>
                            <td
                              class="background-table-gray text-center"
                              style="border-top-width: 0"
                            >
                              <button
                                @click="setPlan('MOVE', moveFinalPrice), navigateNext(), top()"
                                class="btn btn-primary"
                              >
                                Comprar
                              </button>
                            </td>
                            <td
                              class="background-table-yellow text-center"
                              style="border-top-width: 0"
                            >
                              <button
                                @click="
                                  setPlan('MOVE Plus', movePlusFinalPrice), navigateNext(), top()
                                "
                                class="btn btn-primary btn-orange"
                              >
                                Comprar
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- fim tabela de comparacao -->
          <!-- Documentos Uteis -->
          <div class="container mt-4">
            <h5 class="">Faça AQUI download das suas condições para posterior consulta:</h5>
            <div style="list-style-position: inside">
              <span
                ><a
                  target="_blank"
                  :href="`${publicPath}IPID_Move.pdf`"
                  class="color-dark-blue font-weight-bold"
                  >Ficha Informativa Move</a
                ></span
              ><br />
              <!-- <li > <a target="_blank" :href="`${publicPath}`" class="color-dark-blue font-weight-bold">Brochura</a></li> -->
              <span
                ><a
                  target="_blank"
                  :href="`${publicPath}IPID_Move Plus.pdf`"
                  class="color-dark-blue font-weight-bold"
                  >Ficha Informativa Move Plus</a
                ></span
              ><br />
               <span
                ><a
                  target="_blank"
                  :href="`${publicPath}IPID_Move_ Opcionais.pdf`"
                  class="color-dark-blue font-weight-bold"
                  >Ficha Informativa Move e Move Plus Opcionais</a
                ></span
              ><br />
              <span
                ><a
                  target="_blank"
                  :href="`${publicPath}IPC's _Move+Covid+AP+RC.pdf`"
                  class="color-dark-blue font-weight-bold"
                  >Informações Pré-contratuais Move</a
                ></span
              ><br />
              <span
                ><a
                  target="_blank"
                  :href="`${publicPath}IPC's _Move Plus+Covid+AP+RC.pdf`"
                  class="color-dark-blue font-weight-bold"
                  >Informações Pré-contratuais Move Plus</a
                ></span
              ><br />
              <span
                ><a
                  target="_blank"
                  :href="`${publicPath}Clausulado Move+Covid+AP+RC.pdf`"
                  class="color-dark-blue font-weight-bold"
                  >Clausulado Move</a
                ></span
              ><br />
              <span
                ><a
                  target="_blank"
                  :href="`${publicPath}Clausulado Move Plus+Covid+AP+RC.pdf`"
                  class="color-dark-blue font-weight-bold"
                  >Clausulado Move Plus</a
                ></span
              ><br />
            </div>
          </div>
          <!-- Fim Documentos Uteis -->
        </div>
        <!-- fim coluna da direita  -->

        <!-- caixa de email -->
        <div class="col-md-8 col-lg-4 col-sm-10 mx-auto mt-5">
          <div class="card background-gray">
            <div class="card-body">
              <h5 class="card-title title-detalhes">Envie para o seu email</h5>
              <form class="mb-4">
                <div class="form-group">
                  <input
                    type="email"
                    v-model="emailForQuotation"
                    class="form-control"
                    placeholder="nome@email.com"
                  />
                </div>
                <div v-if="blankEmail" class="error">
                  <span v-if="!$v.emailForQuotation.required"
                    >Email necessário</span
                  >
                </div>
                <div v-if="invalidEmail" class="error">
                  <span v-if="!$v.emailForQuotation.email"
                    >Digite um endereço de email válido</span
                  >
                </div>
                <b-form-checkbox
                  id="checkbox-privacy"
                  v-model="privacyStatus"
                  name="checkbox-privacy"
                >
                  Concordo com a
                  <a
                    target="_blank"
                    href="./Política de Privacidade do Site Viagem V07.12.2020.pdf"
                    class="color-dark-blue font-weight-bold"
                  >
                    Política de Privacidade
                  </a>
                  e autorizo ser contatado no âmbito desta simulação.
                </b-form-checkbox>
                <div v-if="submitStatus === false" class="error">
                  <span
                    >É necessário concordar com a política de privacidade</span
                  >
                </div>
                <b-form-checkbox
                  id="checkbox-marketing"
                  v-model="subscribe"
                  name="checkbox-marketing"
                >
                  Desejo ser avisado sobre futuras promoções por email.
                </b-form-checkbox>
                <button
                  @click.prevent="sendEmailWithPrice"
                  type="submit"
                  class="btn btn-primary btn-dark-blue mt-3"
                >
                  Enviar
                </button>
              </form>
              <table class="table mt-2">
                <thead>
                  <tr>
                    <th colspan="2" class="pl-0">
                      <h5 class="card-title title-detalhes mb-0 mt-1">
                        Resumo da viagem
                      </h5>
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="">Destino</td>
                    <td class="">
                      <strong>{{ paises }}</strong>
                    </td>
                  </tr>
                  <tr>
                    <td class="">Data de início</td>
                    <td class="">
                      <strong>{{ dataInicial }}</strong>
                    </td>
                  </tr>
                  <tr>
                    <td class="">Data de fim</td>
                    <td class="">
                      <strong>{{ dataFinal }}</strong>
                    </td>
                  </tr>
                  <tr>
                    <td class="">Número de pessoas</td>
                    <td class="">
                      <strong>{{ insurancePeopleQuantity }}</strong>
                    </td>
                  </tr>
                  <tr>
                    <button
                      class="btn btn-primary btn-dark-blue"
                      @click="navigateBack()"
                    >
                      Alterar
                    </button>
                  </tr>
                </tbody>
              </table>

              <hr />

              <h5 class="card-title title-detalhes">Código promocional</h5>
              <b-form>
                <b-input-group>
                  <b-form-input
                    v-model="promocode"
                    placeholder="Adiciona teu código"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-button
                      @click.prevent="checkPromotionalCode"
                      type="submit"
                      class="btn btn-primary btn-dark-blue"
                      >Adicionar</b-button
                    >
                  </b-input-group-append>
                </b-input-group>
                <div class="mt-1 ml-1">
                  <div v-if="validPromocode == false">
                    <span class="error">{{ this.message }}</span>
                  </div>
                  <div v-if="validPromocode == true">
                    <span style="color: green">{{ this.message }}</span>
                  </div>
                </div>
                <div v-if="promotional" v-html="activePromotional"></div>
              </b-form>
            </div>
          </div>
        </div>
        <!-- fim caixa de email -->
      </div>
    </div>
    <!-- fim do container -->
  </div>
</template>
<script>
import PublicLayout from "@/layouts/PublicLayout.vue";
import StepsHeader from "@/components/StepsHeader.vue";
import BudgetMailerService from "../services/BudgetMailerService";
import InsuranceDataService from "../services/InsuranceDataService";
import { required, email } from "vuelidate/lib/validators";
import moment from "moment";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "Step2",
  components: {
    Loading,
    StepsHeader,
  },
  created() {
    this.$emit("update:layout", PublicLayout);
  },

  data() {
    return {
      publicPath: process.env.BASE_URL,
      privacyStatus: [],
      submitStatus: null,
      blankEmail: false,
      invalidEmail: false,
      emailSenderMessage: "",
      showEmailSentAlert: false,
      subscribe: false,
      // for vue-loading-overlay
      isLoading: false,
      fullPage: true,
      promocode: "",
      validPromocode: null,
      message: "",
      totalDay: "",
      finalPrice: 0,
      viewCoberturaCovid: false,
    };
  },
  computed: {
    activePromotional(){
      let message = ''
      if(this.promotional){
        message = `
          <small>
            <b>Código ativo:</b> ${this.promotional.code}<br>
            <b>Desconto:</b> ${this.promotional.discount}%
          </small>
        `
      }
      return message
    },
    moveFinalPrice() {
      let price = this.insurancePrice.totalValor
      price += (this.insuranceCivilResponsability === true) ? this.insurancePrice.totalApRc : 0;
      price += (this.insuranceCovid === true) ? this.insurancePrice.totalCovid : 0;
      return price.toFixed(2)
    },
    movePlusFinalPrice() {
      let price = this.insurancePrice.totalValorVip
      price += (this.insuranceCivilResponsability === true) ? this.insurancePrice.totalApRcVip : 0;
      price += (this.insuranceCovid === true) ? this.insurancePrice.totalCovidVip : 0;
      return price.toFixed(2)
    },
    emailForQuotation: {
      get() {
        return this.$store.state.emailForQuotation;
      },
      set(value) {
        this.$store.commit("setEmailForQuotation", {
          emailForQuotation: value,
        });
      },
    },
    insuranceCountry: {
      get() {
        return this.$store.state.insurance.country;
      },
    },
    paises() {
      return this.$store.state.insurance.country.join(", ");
    },
    dataInicial() {
      return moment(String(this.$store.state.insurance.initialDate)).format(
        "DD/MM/YYYY"
      );
    },
    dataFinal() {
      return moment(String(this.$store.state.insurance.finalDate)).format(
        "DD/MM/YYYY"
      );
    },
    insuranceInitialDate: {
      get() {
        return this.$store.state.insurance.initialDate;
      },
      set(value) {
        this.$store.commit("setInsuranceInitialDate", {
          insuranceInitialDate: value,
        });
      },
    },
    insuranceFinalDate: {
      get() {
        return this.$store.state.insurance.finalDate;
      },
      set(value) {
        this.$store.commit("setInsuranceFinalDate", {
          insuranceFinalDate: value,
        });
      },
    },
    insurancePeopleQuantity: {
      get() {
        return this.$store.state.insurance.peopleQuantity;
      },
      set(value) {
        this.$store.commit("setInsurancePeopleQuantity", {
          insurancePeopleQuantity: value,
        });
      },
    },
    insurancePeople74YearsQuantity: {
      get() {
        return this.$store.state.insurance.people74YearsQuantity
      },
      set (value) {
        this.$store.commit('setInsurancePeople74YearsQuantity', {insurancePeople74YearsQuantity: value})
      }
    },
    insurancePlan: {
      get() {
        return this.$store.state.insurance.plan;
      },
      set(value) {
        this.$store.commit("setInsurancePlan", { insurancePlan: value });
      },
    },
    insuranceCivilResponsability: {
      get() {
        return this.$store.state.insurance.civilResponsability;
      },
      set(value) {
        this.$store.commit("setInsuranceCivilResponsability", {
          insuranceCivilResponsability: value,
        });
      },
    },
    insurancePersonalAccidents: {
      get() {
        return this.$store.state.insurance.personalAccidents;
      },
      set(value) {
        this.$store.commit("setInsurancePersonalAccidents", {
          insurancePersonalAccidents: value,
        });
      },
    },
    insuranceCovid: {
      get() {
        return this.$store.state.insurance.covid;
      },
      set(value) {
        this.$store.commit("setInsuranceCovid", { insuranceCovid: value });
      },
    },
    insurancePrice: {
      get() {
        return this.$store.state.insurance.price;
      },
      set(value) {
        this.$store.commit("setInsurancePrice", { insurancePrice: value });
      },
    },
    promotional: {
      get() {
        return this.$store.state.promotional;
      },
      set(value) {
        this.$store.commit("setPromotional", value);
      },
    },
  },
  mounted: function () {
    this.calcTotalDay();
    if(isNaN(this.insurancePrice.totalValor) || isNaN(this.insurancePrice.totalValorVip)){
      this.$router.push({ name: "/"});
      window.location.reload()
    }
  },
  validations: {
    emailForQuotation: {
      required,
      email,
    },
  },

  methods: {
    calcTotalDay() {
      var d1 = this.dataInicial;
      var d2 = this.dataFinal;

      var numbersD1 = d1.match(/\d+/g);
      var numbersD2 = d2.match(/\d+/g);
      var date1 = new Date(numbersD1[2], numbersD1[1] - 1, numbersD1[0]);
      var date2 = new Date(numbersD2[2], numbersD2[1] - 1, numbersD2[0]);

      var timeDiff = Math.abs(date2.getTime() - date1.getTime());
      var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
      this.totalDay = diffDays;
    },
    showLoading() {
      this.isLoading = true;
    },
    covidCobertura() {
      var status = this.viewCoberturaCovid;
      this.viewCoberturaCovid = !status;
    },
    checkPromotionalCode() {
      this.validPromocode = null
      var data = {
        country: this.insuranceCountry,
        qtdPeople: this.insurancePeopleQuantity,
        startDate: this.insuranceInitialDate,
        endDate: this.insuranceFinalDate,
        qtdPeopleOver74years: this.insurancePeople74YearsQuantity,
      }
      InsuranceDataService.getPromotionalCode({code: this.promocode})
      .then((response) => {
        data.promocode = response.data.promocode
        this.message = response.data.message;
        if (response.data.valid) {
          InsuranceDataService.getPrice(data)
          .then(response => {
            this.$store.commit('setPromotional', data.promocode)
            this.$store.commit('setInsurancePrice', {insurancePrice: response.data})
          })
          this.validPromocode = true;
        } else {
          this.validPromocode = false;
        }
      })
      .catch((e) => {
        InsuranceDataService.getPrice(data)
        .then(response => {
          this.$store.commit('setPromotional', null)
          this.$store.commit('setInsurancePrice', {insurancePrice: response.data})
        })
        this.validPromocode = false;
        this.message = e.response.data.message;
      });
    },
    sendEmailWithPrice() {
      this.blankEmail = false;
      this.invalidEmail = false;
      this.submitStatus = null;
      if (this.emailForQuotation === "") {
        this.blankEmail = true;
        return;
      }
      this.invalidEmail = this.$v.$invalid;
      if (this.invalidEmail === true) {
        return;
      } else if (this.privacyStatus.length === 0) {
        this.submitStatus = false;
        return;
      } else {
        this.submitStatus = true;
        this.isLoading = true;
        var data = {
          email: this.emailForQuotation,
          initialDate: this.dataInicial,
          finalDate: this.dataFinal,
          countries: this.paises,
          numberOfPeople: this.insurancePeopleQuantity,
          priceStandard: this.moveFinalPrice,
          priceVip: this.movePlusFinalPrice,
          subscribe: this.subscribe,
          insuranceCovid: this.insuranceCovid,
          insuranceCivilResponsability: this.insuranceCivilResponsability

        };
        BudgetMailerService.create(data)
          .then((response) => {
            // alert(response.data.message);
            this.emailSenderMessage = response.data.message;
            this.showEmailSentAlert = true;
            this.emailForQuotation = "";
            this.subscribe = false;
            this.privacyStatus = [];
            window.scrollTo(0, 0);
            this.isLoading = false;
          })
          .catch((e) => {
            alert(e);
          });
      }
    },
    setPlan(plan, price) {
      this.insurancePlan = plan;
      this.finalPrice = price;
    },
    setInsurancePersonalAccidents(value) {
      this.insurancePersonalAccidents = value;
    },
    setInsuranceCivilResponsability(value) {
      this.insuranceCivilResponsability = value;
    },
    setInsuranceCovid(value) {
      this.insuranceCovid = value;
    },
    navigateNext() {
      this.$router.push("/dados");
    },
    navigateBack() {
      this.$router.push("/");
    },
    top() {
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style scoped>
h2 {
  font-size: 27px;
  font-weight: 600;
}
.logo {
  width: 50px;
}
.background-table-gray {
  background-color: #307fe22b;
}
.background-table-yellow {
  background-color: #ff82002b;
}
.title-detalhes {
  font-size: 22px;
}
.title-form-step-1 {
  font-size: 32px;
  font-weight: bold;
}

.color-orange {
  color: #ff8200;
}

.color-dark-blue {
  color: #00239c;
}

.insurance-item {
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.42;
  letter-spacing: normal;
  text-align: left;
  color: #00239c;
}
.insurance-item-coberture {
  font-size: 13px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.42;
  letter-spacing: normal;
  text-align: left;
}

.comparison-item {
  font-size: 14px;
  text-align: center;
}

.comparison-price {
  font-size: 24px;
  text-align: center;
  font-weight: bold;
}

.background-gray {
  background-color: #f8f8f9;
}

.btn-dark-blue,
.btn-dark-blue:hover,
.btn-dark-blue:active,
.btn-dark-blue:visited,
.btn-dark-blue:focus {
  background-color: #00239c;
  border-color: #00239c;
}

.btn-orange,
.btn-orange:hover,
.btn-orange:active,
.btn-orange:visited,
.btn-orange:focus {
  background-color: #ff8200;
  border-color: #ff8200;
}

.form-control[readonly] {
  background-color: none !important;
}
.form-control {
  height: 40px;
}

.title-table {
  font-size: 18px;
}

.title-plans {
  font-size: 26px;
  font-weight: 900;
}

.comparasion-table .toggle {
  font-size: 14px;
  font-weight: 100;
  color: #000000;
}

.error {
  color: red;
}
.custom-control-label {
  font-size: 11px;
  padding: 4px;
}

.btn-primary{
  color: #FFF !important;
}

</style>
