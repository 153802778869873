<template>
  <div id="terms-and-conditions">
    <b-form-checkbox
      id="privacidade"
      v-model="privacyStatus"
      name="checkbox-privacyStatus"
    >
      Declaro ter lido a Declaração de Privacidade e Proteção de dados Pessoais
      da Europ Assistance e a cláusula de protecção de dados que consta das
      condições gerais consentido expressamente nos referidos documentos. Mais
      Declaro serem todos os segurados residentes em Portugal.*
    </b-form-checkbox>
    <div v-if="!privacyStatus && submitted" class="error">
      Item necessário
    </div>

    <b-form-checkbox
      id="apolice"
      v-model="conditions"
      name="checkbox-conditions"
      class="mt-3"
    >
      Declaro ter lido a Ficha Informativa, as Informações Pré-contratuais,
      correspondentes ao seguro por mim escolhido, consentir na sua receção por
      intermédio do presente site e as Condições da Apólice, compreendido
      integralmente o seu conteúdo, estar esclarecido e consentir expressamente
      na celebração do presente contrato
    </b-form-checkbox>
    <div v-if="!conditions && submitted" class="error">
      Item necessário
    </div>

    <b-form-checkbox
      id="residente"
      v-model="resident"
      name="checkbox-resident"
      class="mt-3"
    >
      Consinto de forma expressa na receção da apólice e demais documentação em
      formato eletrónico.
    </b-form-checkbox>
    <div v-if="!resident && submitted" class="error">
      Item necessário
    </div>
  </div>
</template>

<script>
export default {
    name: "TermsAndConditions",
    props:{
        allTermsAccepted:{
          type: Boolean,
          default: false
        },
        submitted: {
            type: Boolean,
            default: false
        }
    },
    data(){
      return {
        privacyStatus: false,
        conditions: false,
        resident: false,
      }
    },
    updated(){
      const acepted = this.privacyStatus && this.conditions && this.resident
      this.$emit('update:allTermsAccepted', acepted)
    }
}
</script>
