<template>
  <div class="payment-success">
    <StepsHeader titulo="MBWay" step="3" />
    <!-- banner inicial -->
    <div class="container mt-4">
      <div class="row">
        <div class="col-md-10 mx-auto">
          <div>
            <!-- <h2
              class="card-title text-center h1-responsive"
            >Seu pedido com código {{insuranceOrderCode}} foi realizado.</h2> -->
            <h2 class="card-title text-center h1-responsive">Efectue o pagamento para confirmar a aquisição do seguro.</h2>
            <p class=" text-center text-center">Para efetuar a compra do seu seguro, confirme o pagamento na sua aplicação Mbway nos próximos 5 minutos.</p>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6 col-lg-5 mx-auto">
          <div class="card border-primary">
            <img
              class="card-img-top mt-4 text-center mx-auto"
              src="@/assets/logo-mbway.png"
              style="width: 300px;"
            />
            <div class="card-body">
              <h5 class="card-title text-center">
                Pagamento por MBWay
              </h5>
              <p class="card-text text-center">
                Confirme o pagamento na App Mbway.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-8 mx-auto">
          <div>
            <!-- <p
              class=" text-center text-center"
            >Pagamento válido até: {{easyPayExpirationTime}} </p> -->
            <p class=" text-center">
              A apólice do seguro será emitida e enviada por email após a confirmação do pagamento.
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- fim banner inicial -->
    <div class="container mt-4">
      <order-resume />
    </div>
  </div>
</template>

<script>
import PublicLayout from "@/layouts/PublicLayout.vue";
import StepsHeader from "@/components/StepsHeader.vue";
import EasyPayService from '@/services/EasyPayService';

export default {
  name: "Payment-success",
  components: {
    StepsHeader
  },
  created() {
    this.$emit("update:layout", PublicLayout);
  },
  mounted() {    
    this.runCheckEvery10s();
  },
  data() {
    return {
      mbwayPaymentStatus: '',
      recurringCheck: null,
    }
  },
  computed: {
    insuranceOrderCode: {
      get() {
        return this.$store.state.insurance.orderCode;
      },
      set(value) {
        this.$store.commit("setInsuranceOrderCode", {
          insuranceOrderCode: value
        });
      }
    },
    easyPayEntity: {
      get() {
        return this.$store.state.insurance.easyPayEntity;
      },
      set(value) {
        this.$store.commit("setEasyPayEntity", { easyPayEntity: value });
      }
    },
    easyPayReference: {
      get() {
        return this.$store.state.insurance.easyPayReference;
      },
      set(value) {
        this.$store.commit("setEasyPayReference", { easyPayReference: value });
      }
    },
    easyPayPrice: {
      get() {
        return this.$store.state.insurance.easyPayPrice;
      },
      set(value) {
        this.$store.commit("setEasyPayPrice", { easyPayPrice: value });
      }
    },
    easyPayExpirationTime: {
      get () {
        return this.$store.state.insurance.easyPayExpirationTime
      },
      set (value) {
        this.$store.commit('setEasyPayExpirationTime', {easyPayExpirationTime: value})
      }
    },
  },
  methods: {
    checkMbWayStatus(){
      var data = {
        order_code: this.insuranceOrderCode 
      };
      EasyPayService.checkPaymentStatus(data)
      .then(response => {
        let status = response.data.payment_status
        this.mbwayPaymentStatus = status
        if(status === 'success'){
          clearInterval(this.recurringCheck)
          this.$router.push('pagamento-concluido')
        }
      })
    },
    runCheckEvery10s(){
      this.recurringCheck = setInterval(() => {
        this.checkMbWayStatus()
      }, 10000)
    }
  },
};
</script>

<style scoped>
</style>