import http from "../http-common";

class EasyPayService {
  createVisa(data) {
    return http.post("/easypay/cc", data);
  }
  createMultiBanco(data) {
    return http.post("/easypay/mb", data);
  }
  createMbway(data) {
    return http.post("/easypay/mbway", data);
  }
  checkPaymentStatus(data) {
    return http.post("/easypay/check-with-order-id", data);
  }
}

export default new EasyPayService();
