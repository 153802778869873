<template>
    <div>
        <h4>Resumo</h4>
        <div>
          {{ insurance.country.length > 1 ? 'Paises' : 'País' }}: <b>{{ insurance.country.join(', ') }}</b><br>
          Data de início: <b>{{ new Date(insurance.initialDate).toLocaleDateString() }}</b><br>
          Data de fim: <b>{{ new Date(insurance.finalDate).toLocaleDateString() }}</b><br>
          Plano: <b>{{ insurance.plan }}</b><br>
          Código de Compra: <b>{{ insurance.orderCode }}</b><br>
          Valor da Compra: <b>{{ chargePrice }} €</b><br>
          
        </div>
        <h4>Tomador</h4>
        <div>
          Nome: <b>{{ tomador.name + ' ' + tomador.surname}}</b><br>
          E-mail: <b>{{ tomador.email }}</b><br>
          Telemóvel: <b>{{ tomador.mobile_number }}</b><br>
          Data de Nascimento: <b>{{ new Date(tomador.date_of_birth).toLocaleDateString() }}</b><br>
          Nif: <b>{{ tomador.nif }}</b><br>
          <template v-if="tomador.bi">Cartão Cidadão: <b>{{ tomador.cc }}</b><br></template>
          <template v-if="tomador.bi">Bilhete de Identidade: <b>{{ tomador.bi }}</b><br></template>
          <template v-if="tomador.bi">Passaporte: <b>{{ tomador.passport }}</b> - <b>{{ tomador.passport_country }}</b></template>
          <template v-if="tomador.bi && tomador.passport">País do Passaporte: <b>{{ tomador.passport_country }}</b></template>
        </div>
        <h4>Morada</h4>
        <div>
          Endereço: <b>{{ address.address }}</b><br>
          Cidade: <b>{{ address.city }}</b><br>
          Localidade: <b>{{ address.location }}</b><br>
          País: <b>{{ address.postalCode }}</b><br>
          Código Postal: <b>{{ address.country }}</b><br>
        </div>
    </div>
</template>
  
<script>
  export default {
    name: 'OrderResume',
    computed: {
        insurance: {
            get() {
                return this.$store.state.insurance;
            },
            set(value) {
                this.$store.commit("setInsurance", {
                    insurance: value,
                });
            },
        },
        insuranceOrderCode: {
            get () {
                return this.$store.state.insurance.orderCode
            },
            set (value) {
                this.$store.commit('setInsuranceOrderCode', {insuranceOrderCode: value})
            }
        },
        tomador: {
            get() {
                return this.$store.state.insurance.persons.find( p => p.tomador === true);
            },
        },
        address: {
            get() {
                return this.$store.state.insurance.address;
            },
        },
        chargePrice () {
            if (this.insurancePlan == 'MOVE') {
                return this.moveFinalPrice
            }else{
                return this.movePlusFinalPrice
            }
        },
        moveFinalPrice() {
            let price = this.insurancePrice.totalValor
            price += parseFloat((this.insuranceCivilResponsability === true) ? this.insurancePrice.totalApRc : 0);
            price += parseFloat((this.insuranceCovid === true) ? this.insurancePrice.totalCovid : 0);
            return price.toFixed(2)
        },
        movePlusFinalPrice() {
            let price = this.insurancePrice.totalValorVip
            price += parseFloat((this.insuranceCivilResponsability === true) ? this.insurancePrice.totalApRcVip : 0);
            price += parseFloat((this.insuranceCovid === true) ? this.insurancePrice.totalCovidVip : 0);
            return price.toFixed(2)
        },
        insurancePrice: {
            get () {
                return this.$store.state.insurance.price
            }
        },
        insuranceCivilResponsability: {
            get () {
                return this.$store.state.insurance.civilResponsability
            }
        },
        insuranceCovid: {
            get () {
                return this.$store.state.insurance.covid
            }
        },
    }
  }
</script>