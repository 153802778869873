<template>    
    <div class="person-form">
        <div class="form-row">
            
            <div class="form-group col-md-6">
                <label>Nome <span class="required">*</span></label>
                <input type="text" v-model="person.name" class="form-control" placeholder="" />
                <div v-if="!person.isValidName() && submitted" class="error">
                    Nome inválido
                </div>
            </div>

            <div class="form-group col-md-6">
                <label>Sobrenome <span class="required">*</span></label>
                <input type="text" v-model="person.surname" class="form-control" placeholder="" />
                <div v-if="!person.isValidSurname() && submitted" class="error">
                    Sobrenome inválido
                </div>
            </div>
            
            <div class="form-group col-md-12">
                <label v-if="person.tomador">Email para envio da apólice <span class="required">*</span></label>
                <label v-else>Email (opcional)</label>
                <input type="text" v-model="person.email" class="form-control" placeholder="" />
                <div v-if="!person.isValidEmail() && submitted" class="error">
                    E-mail inválido
                </div>
            </div>

            <div class="form-group col-md-6">
                <label>Data de Nascimento <span class="required">*</span></label>
                <input type="date" v-model="person.date_of_birth" class="form-control" placeholder="" />
                <div v-if="!person.isValidBirthdayDate() && submitted" class="error">
                    Data mal formatada
                </div>
                <div v-if="person.tomador && !person.has18years() && submitted" class="error">
                    O Tomador do seguro deve ter mais de 18 anos
                </div>
            </div>
            
            <div class="form-group col-md-6">
                <label>Telemóvel <span class="required">*</span></label>
                <input type="text" v-model="person.mobile_number" class="form-control" placeholder="" />
                <div v-if="!person.isValidMobileNumber() && submitted" class="error">
                    Número de telemóvel inválido
                </div>
            </div>
            
            <div class="form-group col-md-6">
                <label>NIF <span class="required">*</span></label>
                <input type="text" @input="toUpperCase('nif')" v-model="person.nif" class="form-control" placeholder="" />
                <div v-if="!person.isValidNIF() && submitted" class="error">
                    NIF inválido
                </div>
            </div>
            
            <h5>Introduza um dos seguintes Documentos:<span class="required">*</span></h5>
            <div v-if="!person.isValidDocs() && submitted" class="error">
                Ao menos um dos documentos abaixo devem ser preenchidos
            </div>
            
            <div class="form-group col-md-6">
                <label>Cartão Cidadão</label>
                <input type="text" v-model="person.cc" @input="toUpperCase('cc')" class="form-control" placeholder="" />
                <div v-if="person.cc && !person.isValidCC() && submitted" class="error">
                    Cartão Cidadão inválido, é necessário conter números e letras (exemplo 012345678AB9)
                </div>
            </div>
            
            <div class="form-group col-md-6">
                <label>Bilhete de Identidade</label>
                <input type="text" v-model="person.bi" @input="toUpperCase('bi')" class="form-control" placeholder="" />
                <div v-if="person.bi && !person.isValidBI() && submitted" class="error">
                    Bilhete de Identidade inválido
                </div>
            </div>
            
            <div class="form-group col-md-6">
                <label>Passaporte</label>
                <input type="text" v-model="person.passport" @input="toUpperCase('passport')" class="form-control" placeholder="" />
                <div v-if="person.passport && !person.isValidPassport() && submitted" class="error">
                    Passaporte inválido
                </div>
            </div>

            <div class="form-group col-md-6">
                <label>País do Passaporte</label>
                <input type="text" v-model="person.passport_country" @input="toUpperCase('passport_country')" class="form-control" placeholder="" />
                <div v-if="person.passport && !person.isValidPassportCountry() && submitted" class="error">
                    País do passaporte é requerido
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import Person from "@/models/person"
export default {
    name: "PersonForm",
    props:{
        person: {
            type: Person,
            required: true
        },
        submitted: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        toUpperCase(input) {
        // Atualiza o valor em letras maiúsculas
            this.person[input] = this.person[input].toUpperCase();
        }
    }
}
</script>

<style scoped>
</style>