<template>
  <div>
    <div v-if="!submitted">
      <b-form>
        <b-form-group
          id="input-group-email"
          label="Endereço de email"
          label-for="email"
          
        >
          <b-form-input
            id="email"
            v-model="contact.email"
            type="email"
            required
            placeholder="Escreva o seu email"
            name="email"
          ></b-form-input>
        </b-form-group>

        <b-form-group id="input-group-name" label="Seu nome:" label-for="name">
          <b-form-input
            id="name"
            v-model="contact.name"
            required
            placeholder="Nome:"
          ></b-form-input>
        </b-form-group>

        <b-form-group label="Mensagem" label-for="text">
          <b-form-textarea
            id="text"
            v-model="contact.text"
            placeholder="Escreva sua mensagem"
            rows="4"
            max-rows="12"
          ></b-form-textarea>
        </b-form-group>
        <b-button @click="saveContact" type="submit" variant="primary">Enviar</b-button>
      </b-form>
    </div>

    <div v-else>
      <h4>Mensagem enviada com sucesso!</h4>
      <b-button @click="newContact" variant="primary">Enviar outra mensagem</b-button>
    </div>
  
  </div>
</template>

<script>
import ContactDataService from "../services/ContactDataService";

export default {
  name: "add-contact",
  data() {
    return {
      contact: {
        id: null,
        email: '',
        name: '',
        text: '',
      },
      submitted: false,
    }
  },
  methods: {
    saveContact() {
      var data = {
        email: this.contact.email,
        name: this.contact.name,
        text: this.contact.text
      };

      ContactDataService.create(data)
        .then(response => {
          this.contact.id = response.data.id;
          // alert(response.data.id);
        })
        .catch(e => {
          alert(e);
        });

      this.submitted = true;
    },

    newContact() {
      this.submitted = false;
      this.contact = {};
    }
  },
};
</script>