import regex from "@/helpers/regex"

export default class Person {
    constructor(person = null){
        try{
            if(person){
                Object.assign(this, person)
            }else{
                this.buildEmpty()
            }
        }catch(e){
            this.buildEmpty()
        }
    }

    buildEmpty(){
        this.name = null,
        this.surname = null,
        this.email = null,
        this.date_of_birth = null,
        this.mobile_number = null,
        this.nif = null,
        this.cc = null,
        this.bi = null,
        this.passport = null,
        this.passport_country = null,
        this.segurado = true,
        this.tomador = false
    }

    hasError(){
        if(this.tomador && !this.has18years()) return true
        return !( 
            this.isValidName() && 
            this.isValidSurname() &&
            this.isValidEmail &&
            this.isValidMobileNumber() &&
            this.isValidNIF() &&
            this.isValidDocs()
        )
    }

    valid(){
        return {
            name : this.isValidName(),
            surname : this.isValidSurname(),
            email : this.isValidEmail(),
            date_of_birth: this.isValidBirthdayDate(),
            has_18_years: this.has18years(),
            mobile_number : this.isValidMobileNumber(),
            nif : this.isValidNIF(),
            cc : this.isValidCC(),
            bi : this.isValidBI(),
            passport : this.isValidPassport(),
        }
    }

    isValidName(){
        if(!this.name) return false
        const name = this.name.replace(/\s+/g,'');
        if(!name || name.length < 3) return false
        return regex.name.test(name)
    }

    isValidSurname(){
        if(!this.surname) return false
        const surname = this.surname.replace(/\s+/g,'');
        if(!surname || surname.length < 3) return false
        return regex.name.test(surname)
    }

    isValidEmail(){
        if(!this.email && this.tomador) return false
        if(!this.email && !this.tomador) return true
        return regex.email.test(this.email)
    }

    isValidMobileNumber(){
        if(!this.mobile_number) return false
        return regex.tel.test(this.mobile_number)
    }

    isValidNIF(){
        if(!this.nif) return false
        const nif = this.nif
        if (
          !["1", "2", "3", "5", "6", "8"].includes(nif.substr(0, 1)) &&
          !["45", "70", "71", "72", "77", "79", "90", "91", "98", "99"].includes(
            nif.substr(0, 2)
          )
        ){ return false; }
        let total =
          nif[0] * 9 +
          nif[1] * 8 +
          nif[2] * 7 +
          nif[3] * 6 +
          nif[4] * 5 +
          nif[5] * 4 +
          nif[6] * 3 +
          nif[7] * 2;
    
        let modulo11 = total - parseInt(total / 11) * 11;
        let comparador = modulo11 == 1 || modulo11 == 0 ? 0 : 11 - modulo11;
    
        if (nif[8] == comparador) {
          return true;
        }
        return false;
    }

    isValidCC(){
        if(!this.cc) return false
        return regex.cc.test(this.cc)
    }

    isValidBI(){
        if(!this.bi) return false
        return regex.bi.test(this.bi)
    }

    isValidPassport(){
        if(!this.passport || this.passport.length < 5) return false
        //return regex.passport.test(this.passport)
        return true
    }

    isValidPassportCountry(){
        if(this.passport && !this.passport_country) return false
        return true
    }

    isValidDocs(){
        return this.isValidBI() ||
            this.isValidCC() ||
            this.isValidPassport() && this.isValidPassportCountry()
        
    }

    isValidBirthdayDate() {
        if(!this.date_of_birth) return false
        return (new Date(this.date_of_birth) !== "Invalid Date") && !isNaN(new Date(this.date_of_birth));
    }

    has18years(){
        var today = new Date();
        var birthDate = new Date(this.date_of_birth);
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age >= 18;
    }

}