<template>
  <div class="container">
    <InsuranceTable />
  </div>
</template>

<script>
import BackofficeLayout from '@/layouts/BackofficeLayout.vue'
import InsuranceTable from '@/components/backoffice/InsuranceTable.vue'


export default {
  name: 'Backoffic',
  components: {
    InsuranceTable,
  },
  created() {
    this.$emit('update:layout', BackofficeLayout)
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    }
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push('/login');
    }
  }
};
</script>