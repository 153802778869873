<template>    
    <div class="address-form">
        <div class="form-row">
            <div class="form-group col-md-12">
                <label>Endereço <span class="required">*</span></label>
                <input type="text" v-model="address.address" class="form-control" placeholder="" />
                <div v-if="!address.isValidAddress() && submitted" class="error">
                    Endereço inválido
                </div>
            </div>
            <div class="form-group col-md-6">
                <label>Cidade <span class="required">*</span></label>
                <input type="text" v-model="address.city" class="form-control" placeholder="" />
                <div v-if="!address.isValidCity() && submitted" class="error">
                    Cidade inválida
                </div>
            </div>
            <div class="form-group col-md-6">
                <label>Localidade <span class="required">*</span></label>
                <input type="text" v-model="address.location" class="form-control" placeholder="" />
                <div v-if="!address.isValidLocation() && submitted" class="error">
                    Localidade inválida
                </div>
            </div>
            <div class="form-group col-md-6">
                <label>Código Postal <span class="required">*</span></label>
                <input type="text" v-model="address.postalCode" class="form-control" placeholder="0000-000" />
                <div v-if="!address.isValidPostal() && submitted" class="error">
                    Código Postal inválido (o formato do código postal deve ser 0000-000)
                </div>
            </div>
            <div class="form-group col-md-6">
                <label>Country <span class="required">*</span></label>
                <input type="text" v-model="address.country" class="form-control" placeholder="" disabled />
            </div>
        </div>
    </div>
</template>

<script>
import Address from "@/models/address"
export default {
    name: "AddressForm",
    props:{
        address: {
            type: Address,
            required: true
        },
        submitted: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style scoped>
</style>