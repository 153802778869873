<template>
  <div class="faq">
    <div class="mt-3">
      <b-container> 

        <b-row>
          <b-col cols="6" class="text-center mx-auto">
            <h1>FAQs</h1>
          </b-col>

          <b-col cols="12" class="mx-auto">
            <p class="question" data-toggle="collapse" href="#faq1" role="button" aria-expanded="false" aria-controls="faq">
              Porquê um Seguro de Viagem?
            </p>
            <div class="collapse" id="faq1">
              <div class="card card-body">
                Porque garantimos  uma assistência permanente, em qualquer parte do mundo, desde o recurso a um hospital, organização de uma consulta médica ou até evitar que tenha de adiantar dinheiro, assegurando-lhe as despesas de saúde e outras para fazer face a algum imprevisto.
              </div>
            </div>

            <p class="question" data-toggle="collapse" href="#faq2" role="button" aria-expanded="false" aria-controls="faq">
              Se precisar de assistência médica, como devo proceder?
            </p>
            <div class="collapse" id="faq2">
              <div class="card card-body">
                Em caso de doença ou acidente no estrangeiro, contate imediatamente o Serviço de Assistência, caracterizando a ocorrência e fornecendo todas as informações necessárias para a prestação da assistência solicitada. <br/>
                Obtenha o acordo do Serviço de Assistência antes de assumir qualquer decisão ou despesa.
              </div>
            </div>

            <p class="question" data-toggle="collapse" href="#faq3" role="button" aria-expanded="false" aria-controls="faq">
              Quais as vantagens de adicionar o Pack de Assistência Covid ao meu seguro?
            </p>
            <div class="collapse" id="faq3">
              <div class="card card-body">
                Ao adicionar o Pack de Assistência Covid ao seu seguro poderá usufruir de coberturas que conferem mais proteção e segurança se testar positivo à Covida 19, nomeadamente: <br/>

                <ul>
                  <li>
                    Pagamento de despesas médicas, cirúrgicas, farmacêuticas  e de hospitalização no Estrangeiro;
                  </li>
                  <li>
                    Consulta médica online e aconselhamento médico telefónico;
                  </li>
                  <li>
                    Aconselhamento Psicológico;
                  </li>
                  <li>
                    Cancelamento e interrupção da viagem;
                  </li>
                </ul>
              </div>
            </div>



            <p class="question" data-toggle="collapse" href="#faq4" role="button" aria-expanded="false" aria-controls="faq">
              O cancelamento antecipado de viagem inclui coronavírus?
            </p>
            <div class="collapse" id="faq4">
              <div class="card card-body">
                Só se adicionar ao seu seguro  o Pack de Assistência Covid. <br/>
                No seguimento de infeção por Covid-19 se tiver de ser internado/a ou permanecer em Quarentena Obrigatória em Portugal, e por esse motivo tiver de  cancelar a sua  viagem, antes da mesma se ter iniciado, asseguraramos o reembolso dos gastos irrecuperáveis de alojamento numa unidade hoteleira ou alojamento local, e transporte mediante comprovativo de liquidação anterior, total ou parcial, e até ao Limite de Capital fixado na Apólice.
              </div>
            </div>

            <p class="question" data-toggle="collapse" href="#faq5" role="button" aria-expanded="false" aria-controls="faq">
              Em que consiste a Linha  Informativa Covid-19?
            </p>
            <div class="collapse" id="faq5">
              <div class="card card-body">
                É uma linha telefónica dedicada ao esclarecimento de dúvidas e recomendações relativamente ao Covid-19 nas suas deslocações de férias e de acordo com as recomendações emitidas pela Direção Geral de Saúde, proporcionando auxílio nessas deslocações à Pessoa Segura.
              </div>
            </div>

            <p class="question" data-toggle="collapse" href="#faq6" role="button" aria-expanded="false" aria-controls="faq">
              Em que circunstâncias posso usufruir da consulta médica online?
            </p>
            <div class="collapse" id="faq6">
              <div class="card card-body">
                Se subscreveu o Pack de Assistência Covid e se, no decurso da sua vigem,  tiver problemas de saúde resultante de infecção por Covid-19, o Serviço de Assistência, organizará uma Vídeo Consulta para orientação médica prestada pela equipa de médicos do Serviço de Assistência, nas condições que sejam compatíveis com as regras da profissão.
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    
  </div>
</template>

<style scoped>
  .question {
    font-size: 18px;
    font-weight: bold;
  }

  .faq ul {
    padding: 0 17px;
  }
</style>

<script>
// @ is an alias to /src
import PublicLayout from '@/layouts/PublicLayout.vue';

export default {
  name: 'Faq',
  created() {
    this.$emit('update:layout', PublicLayout)
  },
}
</script>