<template>
  <div>
    <b-container>

      <b-row>
        <b-col cols="6" class="text-center mx-auto">
         <p class="card-title pt-3 font-bold"><strong>Viaje tranquilo com a Europ Assistance</strong></p>
        </b-col>
      </b-row>



    </b-container>

    <div class="container-fluid">
      <div class="row">
        <div class="col-md-11 mx-auto">
          <slick :key="'sliderKey' + sliderKey" ref="slick" :options="slickOptions">

            <div v-if="isMobile" class="card-carrousel first-slide">
              <div class="card-container">
                <img src="../assets/EAimg4SitViagens.png" class="img-fluid">
              </div>
            </div>

            <div class="card-carrousel">
              <div class="card-container">
                <img src="../assets/EAimg1SegViagemHR.jpg" class="img-fluid">
                <!--
                <div class="conteiner-text">
                  <h4 class="titulo">
                    + Simples
                  </h4>
                  <p class="texto text-center">
                    Assistência médica permanente em todo o mundo.
                  </p>
                </div>
                -->
              </div>
            </div>

            <div class="card-carrousel">
              <div class="card-container">
                <img src="../assets/EAimg2SegViagemHR.jpg" class="img-fluid">
                <!--
                <div class="conteiner-text">
                  <h4 class="titulo">
                    + Eficaz
                  </h4>
                  <p class="texto text-center">
                    Coberturas que se adequam às suas necessidades.
                  </p>
                </div>
              -->
              </div>
            </div>

            <div class="card-carrousel">
              <div class="card-container">
                <img src="../assets/EAimg3SegViagemHR.jpg" class="img-fluid">
                <!--
                <div class="conteiner-text">
                  <h4 class="titulo">
                    + Rápido
                  </h4>
                  <p class="texto text-center">
                    Todas as garantias para viajar tranquilo e em segurança.
                  </p>
                </div>
              -->
              </div>
            </div>

          </slick>
        </div>

        <div class="col-md-8 text-center mx-auto">
          <p>
            <b>
              Não dispensa a consulta de informação contratual e pré-contratual legalmente exigida. <br/>
              <i>Europ Assistance S.A.- Sucursal em Portugal</i>
            </b>
          </p>
        </div>
      </div>
    </div>



  </div>
</template>

<script>



export default {
  data() {
    return {
      sliderKey: 0,
      slickOptions: {
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        dots: false,
        arrows: true,
        responsive: [
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              arrows: false,
              dots: true,
            },
          },
        ],
      },
      isMobile: false
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
  },
  mounted() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      this.isMobile = window.innerWidth <= 599.98;
      this.sliderKey++;
      this.updateSlick();
    },
    updateSlick() {
      // Reinitialize the slick carousel with new options
      this.$nextTick(() => {
        this.$refs.slick.reSlick();
      });
    },
  },
};

</script>

<style scoped>
  .card-carrousel{
    padding: 10px;
    position: relative;

  }
  .conteiner-text{
    position: absolute;
    bottom: 15px;
    text-align: center;
    padding: 35px;
    left: 0;
  }


  .titulo{
    color: #fff;
    font-weight: bold;
  }
  .texto{
    color: #fff;
    text-align:center;
    font-size: 18px;
  }
  .card-container {
    position: relative;
  }
  .card-container::before {
    content: ' ';
    position: absolute;
    /*background: #0c0c0c52;*/
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
</style>
