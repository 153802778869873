<template>
  <div>
    <div class="container">
      <div class="tab-pane fade show active">
        <div class="row">
          <div class="col-md-12">
            <!-- <h3>Here are a list of your insurances</h3> -->
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">Id #</th>
                  <th scope="col">Nome</th>
                  <th scope="col">Qtde pessoas</th>
                  <th scope="col">Países</th>
                  <th scope="col">Valor</th>
                  <!-- <th scope="col">Pago?</th> -->
                  <th scope="col">Status</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                  <tr v-for="insurance in insurances.slice().reverse()" v-bind:key="insurance">
                    <td>{{ insurance.id }}</td>
                    <td>{{ insurance.persons[0].name}} {{ insurance.persons[0].surname}} </td>
                    <td>{{ insurance.people_quantity }}</td>
                    <td>{{ insurance.countries }}</td>
                    <td>{{ insurance.price }}</td>
                    <td>{{ insurance.payment_status }}</td>
                    <!-- <td v-if="insurance.paid == 0 ">Não Pago </td>
                    <td v-else> Pago </td> -->
                    <td ><a href="#" class="btn btn-success">Ver Seguro</a></td>                         
                  </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InsuranceDataService from "@/services/InsuranceDataService";

  export default {
    data() {
      return {
        insurances: [],
      }
    },
    mounted() {
      InsuranceDataService.get()
        .then(response => {
          this.insurances = response.data;
        })
        .catch(e => {
          alert(e);
        });
    }
  }
</script>