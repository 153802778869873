<template>
    <div id="step3">
        <StepsHeader titulo="Os seus dados" step="2" />
        <div class="container mt-5">
            <div class="row justify-content-md-center d-flex">
                <div class="col-md-8 order-2 order-md-1 order-lg-1 order-xl-1">
                    <div id="persons">
                        <div v-if="submittedOnce && hasError" class="error">
                            Há um ou mais campos preenchidos de forma incorreta, por favor, analise os dados inseridos cautelosamente.
                        </div>
                        <div v-for="(person, idx) of seguradosList" :key="idx" class="mt-4 person">
                            <h4>Pessoa {{idx + 1}}</h4>
                            <PersonForm :person="person" :submitted="submittedOnce" />
                        </div>

                        <b-form-checkbox
                            id="tomadorCheck"
                            v-model="seguradoTomador"
                            class="mt-3"
                            @change="checkSeguradoTomador"
                        >
                            <b>O Tomador do Seguro, é também a primeira Pessoa Segura ?</b>
                            <p id="legenda">O tomador de Seguro é a pessoa/empresa responsável pelo pagamento do Seguro.</p>
                        </b-form-checkbox>

                        <div v-if="!tomador.segurado && !seguradoTomador" class="mt-4 person">
                            <h4>Tomador</h4>
                            <PersonForm :person="tomador" :submitted="submittedOnce" />
                        </div>
                    </div>
                    
                    <div id="address">
                        <h4 class="mt-4">Morada</h4>
                        <AddressForm :address="address" :submitted="submittedOnce" />
                    </div>

                    <div id="terms-and-conditions">
                        <h4 class="mt-4">Termos e Condições</h4>
                        <p class="my-4">Certifique-se de ler os termos do seu seguro.</p>
                        <TermsAndConditions :allTermsAccepted.sync="allTermsAccepted" :submitted="submittedOnce" />
                    </div>
                    
                </div>
                <div class="col-md-8 col-lg-4 col-sm-10 order-1 mb-4">
                    <ResumeBox />
                </div>

            </div>

            <button @click="nextStep()/* navigateNext(), top(), isValidDocs()*/" class="btn btn-primary btn-orange my-4">
                Continuar
            </button>
        </div>
    </div>
</template>

<script>
import InsuranceDataService from "../services/InsuranceDataService";
import PublicLayout from "@/layouts/PublicLayout.vue";
import ResumeBox from "@/components/ResumeBox.vue";
import StepsHeader from "@/components/StepsHeader.vue";
import PersonForm from "@/components/PersonForm.vue";
import AddressForm from "@/components/AddressForm.vue";
import TermsAndConditions from "@/components/TermsAndConditions.vue";
import Person from "@/models/person";
import Address from "@/models/address";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
    name: "Step3",
    components: {
        ResumeBox,
        StepsHeader,
        PersonForm,
        AddressForm,
        TermsAndConditions,
    },
    data(){
        return{
            personsList: [],
            address: new Address(),
            seguradoTomador: true,
            extraPerson: new Person(),
            submittedOnce: false,
            allTermsAccepted: false,
            responseErrorMessage: ''
        }
    },
    created(){
        if(this.personsList.length){
            for(let person of this.personsList){
                this.personsList.push(new Person(person))
                if(!person.segurado && person.tomador){
                    this.seguradoTomador = false
                }
            }
        }else{
            for(let i=0; i < this.insurance.peopleQuantity; i++){
                const person = new Person()
                person.segurado = true
                person.tomador = false
                if(i == 0){
                    person.tomador = true
                }
                this.personsList.push(person)
            }
        }
        this.address = new Address(this.insurance.address)
        this.$emit("update:layout", PublicLayout)
    },
    mounted(){
        if(isNaN(this.insurance.price.totalValor) || isNaN(this.insurance.price.totalValor)){
            this.$router.push({ name: "/"});
            window.location.reload()
        }
    },
    computed:{
        insurance: {
            get() {
                return this.$store.state.insurance;
            },
            set(value) {
                this.$store.commit("setInsurance", {
                    insurance: value,
                });
            },
        },
        promotional: {
            get() {
                return this.$store.state.promotional;
            },
            set(promotional) {
                this.$store.commit("setPromotional", promotional);
            },
        },
        hasError(){
            for(let person of this.personsList){
                if(person.hasError())   return true
            }
            if(this.address.hasError()) return true
            if(!this.allTermsAccepted)  return true
            return false
        },
        insuranceId: {
            get() {
                return this.$store.state.insurance.id;
            },
            set(value) {
                this.$store.commit("setInsuranceId", { insuranceId: value });
            },
        },
        insuranceOrderCode: {
            get() {
                return this.$store.state.insurance.orderCode;
            },
            set(value) {
                this.$store.commit("setInsuranceOrderCode", {
                insuranceOrderCode: value,
                });
            },
        },
        seguradosList(){
            return this.personsList.filter( person => { return person.segurado })
        },
        tomador(){
            return this.personsList.find( person => { return person.tomador })
        }
    },
    methods:{
        updatePerson(person){
            this.personsList[person.id] = person.data
        },
        checkSeguradoTomador(){
            if(!this.seguradoTomador){
                this.personsList[0].tomador = false
                this.personsList.push(this.extraPerson)
                this.personsList[this.personsList.length - 1].tomador = true
                this.personsList[this.personsList.length - 1].segurado = false
            }else{
                this.extraPerson = this.personsList[this.personsList.length - 1]
                this.personsList[0].tomador = true
                this.personsList.splice(-1);
            }
        },
        scrollTo(elem, kind = 'id') {
            let element = (kind === 'id')
                ? document.getElementById(elem)
                : document.getElementsByClassName(elem)
            if(element){
                element.scrollIntoView({ behavior: 'smooth' });
            }
        },
        chargePrice() {
            if (this.insurance.plan == "MOVE") {
                return this.moveFinalPrice();
            } else {
                return this.movePlusFinalPrice();
            }
        },
        moveFinalPrice() {
            let price = parseFloat(this.insurance.price.totalValor);
            price += parseFloat(
                this.insurance.civilResponsability === true
                ? this.insurance.price.totalApRc
                : 0
            );
            price += parseFloat(
                this.insurance.covid ? this.insurance.price.totalCovid : 0
            );
            return price.toFixed(2);
        },
        movePlusFinalPrice() {
            let price = parseFloat(this.insurance.price.totalValorVip);
            price += parseFloat(
                this.insurance.civilResponsability === true
                ? this.insurance.price.totalApRcVip
                : 0
            );
            price += parseFloat(
                this.insurance.covid ? this.insurance.price.totalCovidVip : 0
            );
            return price.toFixed(2);
        },
        async saveInsurance() {
            var data = {
                countries: this.insurance.country.join(),
                initial_date: this.insurance.initialDate,
                final_date: this.insurance.finalDate,
                people_quantity: this.insurance.peopleQuantity,
                plan: this.insurance.plan,
                plan_select: {
                covid: this.insurance.covid,
                ApRc: this.insurance.civilResponsability,
                },
                price: this.chargePrice(),
                persons: this.personsList,
                addresses: [
                {
                    line_1: this.address.address,
                    city: this.address.city,
                    location: this.address.location,
                    postal_code: this.address.postal_code,
                    country: this.address.country,
                },
                ],
                promotionalcode_id: null
            };
            if(this.promotional)
                data.promotional = this.promotional.id
            return await InsuranceDataService.create(data)
            .then((response) => {
                this.insuranceId = response.data.id;
                this.insuranceOrderCode = response.data.order_code;
                this.responseErrorMessage =''
                return true
            })
            .catch((e) => {
                this.responseErrorMessage = e.response.data.errorMessage
                return false
            });
        },
        async nextStep(){
            this.submittedOnce = true
            if(this.hasError){
                this.scrollTo('step-bar')
                return
            }
            this.$store.commit('setInsurancePersons', this.personsList)
            this.$store.commit('setInsuranceAddress', this.address)
            await this.saveInsurance()
            .then( saved =>{
                if(saved){
                    const tomador = this.personsList.find(person =>{ return person.tomador});
                    this.$store.commit("setInsuranceEmail", {
                        email: tomador.email,
                    });
                this.$router.push("/pagamento");
                }else{
                    return
                }
            })
        }
    }
}
</script>

<style>
    .title-pessoa {
        font-weight: 700;
        font-size: 22px;
    }
    label {
        font-weight: 700;
    }
    #terms label {
        font-weight: 500;
        font-size: 15px;
    }
    .btn-orange {
        background-color: #ff8200;
        border: 0;
    }
    .error {
        font-size: 0.85em;
        color: red;
    }

    .required {
        color: red;
    }

    #legenda {
        color: gray;
        font-size: 15px;
    }

    .custom-control-label{
        font-weight: normal;
    }

    .custom-checkbox{
        cursor: pointer;
    }
</style>