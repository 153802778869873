<template>
<div class="payment">
  <div class="container mt-5">
    <div class="row justify-content-md-center d-flex">

    <!-- inicio coluna da direita  -->
      <!-- change order on sx and sm: https://stackoverflow.com/a/52301640/3650719 -->
      <div class="col-md-8 order-2 order-md-1 order-lg-1 order-xl-1">
        
        <!-- inicio dados do cartao -->
          <div class="container">
            <h4>Não foi possível processar seu pagamento. Tente novamente ou utilize outro meio de pagamento:</h4>
            <br>
              <a :href=easyPayUrl><button><img class="img-fluid" src="@/assets/easypay-cc.png"></button></a>
          </div>
          <br>
        <!-- fim dados dos cartao -->

      </div>
    <!-- fim coluna da direita  -->

    <!-- caixa de resumo -->
    <!-- <div class="col-md-4 order-1 mb-4">
        <div class="card background-gray">
          <div class="card-body">
            <table class="table mt-2">
              <thead>
                <tr>
                  <th colspan="2" class="border-0" style="font-size: 24px;">Resumo das cotações</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="border-bottom border-top-0">Plano</td>
                  <td class="text-center border-bottom border-top-0"><strong>{{insurance.plan}}</strong></td>
                </tr>
              </tbody>
            </table>
            <div class="card-text font-weight-bolder ml-2" style="font-size: 10px;">
              PRÉMIO FINAL
            </div>
            <div class="card-text font-weight-bolder ml-2 mb-4" style="font-size: 36px;">
              {{insurance.price}} €
            </div>

            <table class="table mt-2">
              <thead>
                <tr>
                  <th colspan="2" class="" style="font-size: 24px;">Resumo da viagem</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="">Destino</td>
                  <td class=""><strong>{{insurance.country}}</strong></td>
                </tr>
                <tr>
                  <td class="">Data de início</td>
                  <td class=""><strong>{{insurance.initialdate}}</strong></td>
                </tr>
                <tr>
                  <td class="">Data de fim</td>
                  <td class=""><strong>{{insurance.finaldate}}</strong></td>
                </tr>
                <tr>
                  <td class="">Número de pessoas</td>
                  <td class=""><strong>{{insurance.peoplequantity}}</strong></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div> -->
    <!-- fim caixa resumo -->
    </div>
  </div>
</div>
</template>

<script>
// @ is an alias to /src
import PublicLayout from '@/layouts/PublicLayout.vue';

export default {
  name: 'Payment',
  created() {
    this.$emit('update:layout', PublicLayout)
  },
  computed: {
    easyPayUrl: {
      get () {
        return this.$store.state.insurance.easyPayUrl
      }
    },
  }
}
</script>

<style scoped>

</style>