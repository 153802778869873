<template>
  <div id="app">
    <Navbar />
    <router-view/>  
    <!-- <Selo /> -->
    <!-- For privacy cookie edit: -->
    <!-- https://github.com/apertureless/vue-cookie-law#readme -->
    <!-- https://vuejsfeed.com/blog/using-the-vue-cookie-law-plugin-to-let-users-know-about-website-cookies -->
    <cookie-law theme="blood-orange" 
    buttonText="Aceita"
    buttonLink="./Política de Privacidade do Site Viagem V07.12.2020.pdf"
    buttonLinkText="Ler Mais"
    position="top" 
    transitionName="fade"
    message="Este website utiliza cookies para oferecer uma maior experiência de navegação.
    Ao prosseguir a navegação com cookies activos está a consentir na utilização."
    >
    </cookie-law>
    <Footer />
  </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
import Navbar from '@/components/Navbar.vue'
// import Selo from '@/components/Selo.vue'
import Footer from '@/components/Footer.vue'
import CookieLaw from 'vue-cookie-law'



export default {
  name: 'app',
  components: {
    // HelloWorld,
    // Selo,
    Navbar,
    Footer,
    CookieLaw
  }
}
</script>

<style>
#app {
  font-family: 'Source Sans Pro', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* color: #2c3e50; */
}
.slick-prev:before, .slick-next:before{
  color: #ccc !important;
}

.card-title{
  font-size: 26px;
  font-weight: bold;
}
.center-div{
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}

#testimonials .slick-slide:not(.slick-current){
    margin:25px 0px !important;
    transition: all 1s;
  }

.background-gray{
  background-color:#f8f8f9;
}


.title-detalhes{
  font-size: 22px;
}


.first-line-table {
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
}
/* #nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
} */
  #banner .slick-prev {
    left: 15px !important;
    z-index: 1 !important;
  }
  #banner .slick-next {
    right: 15px !important;
    z-index: 1 !important;
  }
  #banner .slick-dots {
    z-index: 1;
    display: block;
    bottom: 85px;
  }
  #banner .slick-dots li button:before{
    color: white;
  }
  #banner .slick-dots li.slick-active button:before {
    opacity: .75;
    color: white;
  }



  #banner .slick-prev:before {
    content: " ";
    background: url('~@/assets/arrowLeft.png');
    width: 13px;
    height: 13px;
    position: absolute;
    background-repeat: no-repeat;
    background-size: contain;
  }

  #banner .slick-next:before {
    content: " ";
    background: url('~@/assets/arrowRight.png');
    width: 13px;
    height: 13px;
    position: absolute;
    background-repeat: no-repeat;
    background-size: contain;
  }
</style>
