<template>
  <div class="home">
    <Step1 />
    <Features />
    <Testimonials />
  </div>
</template>

<script>
// @ is an alias to /src
import PublicLayout from '@/layouts/PublicLayout.vue';
import Step1 from '@/components/Step1.vue';
import Testimonials from '@/components/Testimonials.vue';
import Features from '@/components/Features.vue';


export default {
  name: 'home',
  created() {
    this.$emit('update:layout', PublicLayout)
  },
  components: {
    Step1,
    Testimonials,
    Features

  }
}
</script>
