import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from "vuex-persistedstate";
import moment from 'moment'
import { auth } from './authModule';

Vue.use(Vuex);

const initialData = {
  insurance: {
    id: null,
    country: [],
    initialDate: moment().add(1, 'days').toDate(),
    finalDate: moment().add(8, 'days').toDate(),
    peopleQuantity: 1,
    people74YearsQuantity: '0',
    plan: '',
    civilResponsability: false,
    personalAccidents: false,
    covid: true,
    price: '',
    email: '',
    persons: [],
    address: {
      address: '',
      city: '',
      location: '',
      postalCode: '',
      country: 'Portugal',
    },
    terms: '',
    orderCode: '',
    easyPayUrl: '',
    easyPayEntity: '',
    easyPayReference: '',
    easyPayPrice: '',
    easyPayExpirationTime: '',
    // ...
  },
  promotional: null,
  emailForQuotation: '',
  // ...
}

export default new Vuex.Store(
  {
    /* Godd for local debugging, bad for performance in production! */
    strict: process.env.NODE_ENV !== 'production',
    modules: {
      auth
    },
    plugins: [createPersistedState({
      storage: window.sessionStorage,
    })],
    state: initialData,
    
    mutations: {
      clearInsurance(state, payload) {
        state.insurance = initialData.insurance;
      },
      setInsurance(state, payload) {
        state.insurance = payload;
      },
      setInsurancePersons(state, payload){
        state.insurance.persons = payload
      },
      setInsuranceAddress(state, payload){
        state.insurance.address = payload
      },
      setInsuranceId(state, payload) {
        state.insurance.id = payload.insuranceId;
      },
      setInsuranceCountry(state, payload) {
        state.insurance.country = payload.insuranceCountry;
      },
      setInsuranceInitialDate(state, payload) {
        state.insurance.initialDate = payload.insuranceInitialDate;
      },
      setInsuranceFinalDate(state, payload) {
        state.insurance.finalDate = payload.insuranceFinalDate;
      },
      setInsurancePeopleQuantity(state, payload) {
        state.insurance.peopleQuantity = payload.insurancePeopleQuantity;
      },
      setInsurancePeople74YearsQuantity(state, payload) {
        state.insurance.people74YearsQuantity = payload.insurancePeople74YearsQuantity;
      },
      setInsurancePlan(state, payload) {
        state.insurance.plan = payload.insurancePlan;
      },
      setInsuranceCivilResponsability(state, payload) {
        state.insurance.civilResponsability = payload.insuranceCivilResponsability;
      },
      setInsurancePersonalAccidents(state, payload) {
        state.insurance.personalAccidents = payload.insurancePersonalAccidents;
      },
      setInsuranceCovid(state, payload) {
        state.insurance.covid = payload.insuranceCovid;
      },
      setInsurancePrice(state, payload) {
        state.insurance.price = payload.insurancePrice;
      },
      setEmailForQuotation(state, payload) {
        state.emailForQuotation = payload.emailForQuotation;
      },
      setInsurancePersonName(state, payload) {
        state.insurance.person.name = payload.insurancePersonName;
      },
      setInsurancePersonSurname(state, payload) {
        state.insurance.person.surname = payload.insurancePersonSurname;
      },
      setInsuranceEmail(state, payload) {
        state.insurance.email = payload.email;
      },
      setInsurancePersonBirthday(state, payload) {
        state.insurance.person.birthday = payload.insurancePersonBirthday;
      },
      setInsurancePersonMobileNumber(state, payload) {
        state.insurance.person.mobileNumber = payload.insurancePersonMobileNumber;
      },
      setInsuranceAddressLine1(state, payload) {
        state.insurance.address.line1 = payload.insuranceAddressLine1;
      },
      setInsuranceAddressLine2(state, payload) {
        state.insurance.address.line2 = payload.insuranceAddressLine2;
      },
      setInsuranceAddressSegurado(state, payload) {
        state.insurance.address.adderessSegurado = payload.insuranceAddressSegurado;
      },
      setInsuranceAddressCity(state, payload) {
        state.insurance.address.city = payload.insuranceAddressCity;
      },
      setInsuranceAddressCitySegurado(state, payload) {
        state.insurance.address.citySegurado = payload.insuranceAddressCitySegurado;
      },
      setInsuranceAddressLocation(state, payload) {
        state.insurance.address.location = payload.insuranceAddressLocation;
      },
      setInsuranceAddressLocationSegurado(state, payload) {
        state.insurance.address.locationSegurado = payload.insuranceAddressLocationSegurado;
      },
      setInsuranceAddressPostalCode(state, payload) {
        state.insurance.address.postalCode = payload.insuranceAddressPostalCode;
      },
      setInsuranceAddressPostalCodeSegurado(state, payload) {
        state.insurance.address.postalCodeSegurado = payload.insuranceAddressPostalCodeSegurado;
      },
      setInsuranceTerms(state, payload) {
        state.insurance.terms = payload.insuranceTerms;
      },
      setInsuranceOrderCode(state, payload) {
        state.insurance.orderCode = payload.insuranceOrderCode;
      },
      setEasyPayUrl(state, payload) {
        state.insurance.easyPayUrl = payload.easyPayUrl;
      },
      setEasyPayEntity(state, payload) {
        state.insurance.easyPayEntity = payload.easyPayEntity;
      },
      setEasyPayReference(state, payload) {
        state.insurance.easyPayReference = payload.easyPayReference;
      },
      setEasyPayPrice(state, payload) {
        state.insurance.easyPayPrice = payload.easyPayPrice;
      },
      setEasyPayExpirationTime(state, payload) {
        state.insurance.easyPayExpirationTime = payload.easyPayExpirationTime;
      },
      setPromotional(state, payload){
        state.promotional = payload
      }
    }
  });