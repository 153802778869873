<template>
  <div>
    <div class="container-fluid header">
      <div class="row center-div" style="height: 73%;">
        <div class="col-md-12 text-center">
          <p class="title">
            {{this.titulo}}
          </p>
          <p class="title">
            <font-awesome-icon icon="angle-down" />
          </p>
        </div>
      </div>

      <div class="container steps-nav">
        <div id="step-bar" v-if="this.step<4">
          <div class="step-item" v-bind:class="{ active: this.step>0, current: this.step==0 }" id="step0">
            <p class="step-text" v-if="this.step>0">
              <span class="circle ativado">✓</span>
              <a href="/">VIAGEM</a>
            </p>
            <p class="step-text" v-else>
              <span class="circle">1</span> 
              VIAGEM
            </p>
          </div>

          <div class="step-item" v-bind:class="{ active: this.step>1, current: this.step==1 }" id="step1">
            <p class="step-text" v-if="this.step>1">
              <span class="circle ativado">✓</span>
              <a href="/planos">PLANOS</a>
            </p>
            <p class="step-text" v-else>
              <span class="circle">2</span> 
              PLANOS
            </p>
          </div>

          <div class="step-item" v-bind:class="{ active: this.step>2, current: this.step==2 }" id="step2">
            <p class="step-text" v-if="this.step>2">
              <span class="circle ativado">✓</span>
              <a href="/dados">INFORMAÇÕES PESSOAIS</a>
            </p>
            <p class="step-text" v-else>
              <span class="circle">3</span> 
              INFORMAÇÕES PESSOAIS
            </p>
          </div>

          <div class="step-item" v-bind:class="{ active: this.step>3, current: this.step==3 }" id="step3">
            <p class="step-text" v-if="this.step>3">
              <span class="circle ativado">✓</span>
              <a href="/dados">PAGAMENTO</a>
            </p>
            <p class="step-text" v-else>
              <span class="circle">4</span> 
              PAGAMENTO
            </p>
          </div>

          <div class="step-item" v-bind:class="{ active: this.step==4, current: this.step==4 }" id="step4">
            <p class="step-text" v-if="this.step==4">
              <span class="circle ativado">✓</span>
              <a href="/dados">CONFIRMAÇÃO</a>
            </p>
            <p class="step-text" v-else>
              <span class="circle">5</span> 
              CONFIRMAÇÃO
            </p>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'StepsHeader',
  props: {
    titulo: String,
    step: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .header{
    background-color: #00239c;
    height: 300px;
    margin-top: 1px;
  }
  .active::before{
    content: " ";
  }
  .title{
    color: #ffffff;
    font-weight: bold;
    font-size:29px;
    margin: 0;
  }

  #step-bar{
    display: flex;
    justify-content: space-around;
    align-items: center;
    background: #FFF;
    padding: 10px;
  }
  
  .step-item{
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  .step-item .step-text{
    display: inline;
    margin: 0;
    padding: 0;
    color: #999;
  }
  
  .step-item.current .step-text{
    color: #000;
  }

  .step-item a{
    color: #000;
    text-decoration: none;
    transition: 0.3s;
  }

  .step-item a:hover{
    color:#65dcb1;
    transition: 0.3s;
  }

  .circle{
    width: 35px;
    height: 35px;
    border: 2px solid #65dcb1;
    margin-right: 3px;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #fff;
    background-color: #65dcb1;
    font-weight: bold;
  }
  .circle.ativado{
    background-color: transparent;
    color: #65dcb1;
  }
  .active .circle{
    display: none;
  }
  .active .ativado{
    display: inline-block;
  }
  @media(max-width: 780px){
    .header{
      height: auto;
    }
    .steps{
      height: auto;
    }
    .steps-nav{
      display: none;
    }
  }
</style>
