import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Faq from "@/views/Faq.vue";
import FaleConnosco from "@/views/Contact.vue";
import Step2 from "@/components/Step2.vue";
import Step3 from "@/components/Step3.vue";
import Step4 from "@/components/Step4.vue";
import PagamentoRecusado from "@/views/PaymentError.vue";
import PagamentoConcluido from "@/views/PaymentSuccess.vue";
import PagamentoMultiBanco from "@/views/PaymentMultiBanco.vue";
import PagamentoMbway from "@/views/PaymentMbway.vue";
// import Login from "@/components/backoffice/Login.vue";
import Backoffice from "@/views/Backoffice.vue";
import Login from "@/views/Login.vue";
import RegisterAdmin from "@/views/RegisterAdmin.vue";

Vue.use(VueRouter);

// ATENCAO!!!!!
// apos criar rota adicione a pagina em 'public pages' ao fim deste arquivo, se for uma pagina de acesso publico
const routes = [
  { path: "/", name: "Home", component: Home },
  { path: "/planos", name: "Step2", component: Step2 },
  { path: "/dados", name: "Step3", component: Step3 },
  { path: "/pagamento", name: "Step4", component: Step4 },
  { path: "/pagamento-recusado", name: "PagamentoRecusado", component: PagamentoRecusado },
  { path: "/pagamento-concluido", name: "PagamentoConcluido", component: PagamentoConcluido },
  { path: "/pagamento-multibanco", name: "PagamentoMultiBanco", component: PagamentoMultiBanco },
  { path: "/pagamento-mbway", name: "PagamentoMbway", component: PagamentoMbway },
  { path: "/quem-somos", name: "QuemSomos", component: () => import("../views/About.vue") },
  { path: "/faq", name: "Faq", component: Faq },
  { path: "/fale-connosco", name: "FaleConnosco", component: FaleConnosco },
  { path: "/login", name: "Login", component: Login },
  { path: '/register', component: RegisterAdmin },
  { path: "/backoffice", name: "Backoffice", component: Backoffice },
  {
    path: '/admin',
    name: 'AdminAccess',
    // lazy-loaded
    component: () => import("../views/AdminAccess.vue")
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
});

export default router;