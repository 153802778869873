<template>
    <component :is="layout">
      <router-view :layout.sync="layout"/>  
    </component>
</template>

<script>
// https://markus.oberlehner.net/blog/dynamic-vue-layout-components/

// import HelloWorld from './components/HelloWorld.vue'
// import Navbar from './components/Navbar.vue'
// import Footer from './components/Footer.vue'

export default {
  name: 'app',
  data () {
    return {
      layout: 'div',
    };
  },
  // components: {
  //   // HelloWorld,
  //   Navbar,
  //   Footer,
  // }
}
</script>

<style>
#app {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 18px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* color: #2c3e50; */
}

#content {
    margin-top: -50px;
    background: #fff;
    text-align: justify;
}

.navbar{
  border-bottom: 1px solid #FFF;
  font-size: 16px;
}

.banner {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #00239c;
  height: 400px;
  text-align: center;
}

.banner img{
  margin: auto;
}

h1, h2, h3, h4, h5, h6{
  font-weight: bold;
}

h3{
  font-size: 32px;
}

h4{
  font-size: 24px;
  margin: 30px 0px;
}

h4.subtitle-content{
  margin: 0px;
  margin-bottom: 40px;
}

</style>
