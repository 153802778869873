import http from "../http-common";

class InsuranceDataService {
  create(data) {
    return http.post("/insurance/add_with_persons_and_addresses", data);
  }
  get() {
    return http.get("/insurance")
  }
  getPrice(data) {
    return http.post("/insurance/calculateprice", data)
  }
  getPromotionalCode(data) {
    return http.post("/promotionalcode/", data)
  } 
}

export default new InsuranceDataService();