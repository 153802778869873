<template> 
  <div class="mt-3">
    <b-container fluid> 

      <b-row>
        <b-col cols="6" class="text-center mx-auto">
         <p class="card-title pt-3 font-bold"><strong>Testemunhos</strong></p>
        </b-col>
      </b-row>

      <div class="row p-0">
        <div class="col-md-12 p-0" id="testimonials">
          <slick ref="slick2" :options="slickOptions2">
            
            <div class="card-carrousel">
              <div class="card-testimonials text-center center-div">
                <div>
                  <div class="aspas">
                    <b-img :src="require('../assets/aspa.png')" fluid class="mx-auto"></b-img>
                  </div>
                  <div class="texto">
                    Quando necessitar de fazer um novo seguro de viagem é, sem dúvida, com a Europ Assistance que voltarei a subscrever. Vou indicar a vossa empresa a todos os meus amigos e familiares que necessitem de contratar um seguro.<br/>
                    Agradeço todo o vosso profissionalismo. <br/>
                    <br/>
                    <i>Hernâni G. (Coimbra)</i>
                  </div>
                </div>
              </div>
            </div>

            <div class="card-carrousel">
              <div class="card-testimonials text-center">
                <div>
                  <div class="aspas">
                    <b-img :src="require('../assets/aspa.png')" fluid class="mx-auto"></b-img>
                  </div>
                  <div class="texto">
                    Queria enaltecer toda a organização da Europ Assistance, pela forma como fui acompanhado, tratado e por todo o cuidado na assistência que me foi dada durante todo o percurso de tratamento e de reencaminhamento para casa.<br/>
                    <br/>
                    <i>Miguel C. (Porto)</i>
                  </div>
                </div>
              </div>
            </div>

            <div class="card-carrousel">
              <div class="card-testimonials text-center">
                <div>
                  <div class="aspas">
                    <b-img :src="require('../assets/aspa.png')" fluid class="mx-auto"></b-img>
                  </div>
                  <div class="texto">
                    Só quem realmente passa por uma necessidade de assistência, como eu passei, é que pode dar valor ao elevado serviço prestado pela Vossa equipa.<br/>
                    Muito obrigado por tudo.<br/>
                    <br/>
                    <i>João A. (Lisboa)</i>
                  </div>
                </div>
              </div>
            </div>
         </slick>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>


  export default {
      data() {
        return {
          slickOptions2: {
            centerMode: true,
            centerPadding: '0px',
            slidesToShow: 3,
            focusOnSelect: true,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 10000,
            responsive: [
              {
                breakpoint: 768,
                settings: {
                  arrows: false,
                  centerMode: true,
                  centerPadding: '40px',
                  slidesToShow: 1
                }
              },
              {
                breakpoint: 480,
                settings: {
                  arrows: false,
                  centerMode: true,
                  centerPadding: '40px',
                  slidesToShow: 1
                }
              }
            ]
          },
        }
      },
      methods: {
          next() {
              this.$refs.slick2.next();
          },

          prev() {
              this.$refs.slick2.prev();
          },

          reInit() {
              // Helpful if you have to deal with v-for to update dynamic lists
              this.$nextTick(() => {
                  this.$refs.slick2.reSlick();
              });
          },
      },
  }
</script>



<style scoped>
  
  .card-testimonials{
    background-color: #00239c;
    padding: 30px;
    height: 300px;
    color: #fff;
    position: relative;
    filter:grayscale(1)
    opacity(0.2);
    transition: all 1s;
  }
  .aspas{
    /* position: absolute; */
    top: 25px;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
  .texto{
    font-weight: bold;
    font-size: 13px;
    margin-top: 25px;
    max-height: 160px;
    min-height: 160px;
  }
  .profile {
    display: none;
    margin-top: -60px;
    z-index: 2;
    position: relative;
  }
  .profile-name {
    font-weight: bold;
    margin: 0;
  }

  .profile-city{
    font-size: 12px;
  }
  .slick-current .profile,.slick-current .card-testimonials{
    display: block;
    filter:grayscale(0)
    opacity(1);
    transition: all 1s;
  }

  .slick-current{
    width: 33% !important;
  }


  .card-carrousel{
    position: relative;
    padding: 5px;
    height: 290px;
  }

  .img-cover{
    border-radius: 50%;
    width: 110px;
    height: 110px;
    object-fit: cover;
  }

  @media (max-width:768px){
    .card-testimonials{
      height: 700px;
    }
  }
</style>