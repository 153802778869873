<template>
  <div class="card background-gray">
    <div class="card-body">
      <table class="table mt-2">
        <thead>
          <tr>
            <th colspan="2" class="border-0 title-detalhes">
              Resumo das cotações
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="border-bottom border-top-0">Plano</td>
            <td class="text-center border-bottom border-top-0">
              <strong>{{ insurancePlan }}</strong>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="card-text font-weight-bolder ml-2" style="font-size: 10px">
        PRÉMIO FINAL
      </div>
      <div
        class="card-text font-weight-bolder ml-2 mb-4"
        style="font-size: 36px"
      >
        <span>{{ chargePrice }} €</span>
      </div>
      <div v-if="promotional" class="card-text ml-2" style="font-size: 12px">
        Código promocional ativo: <b>{{ promotional.code }}</b
        ><br />
        Desconto: <b>{{ promotional.discount }} %</b>
      </div>

      <table class="table mt-2">
        <thead>
          <tr>
            <th colspan="2" class="title-detalhes">Resumo da viagem</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="">Destino</td>
            <td class="">
              <strong>{{ paises }}</strong>
            </td>
          </tr>
          <tr>
            <td class="">Data de início</td>
            <td class="">
              <strong>{{ insuranceInitialDate | formatDate }}</strong>
            </td>
          </tr>
          <tr>
            <td class="">Data de fim</td>
            <td class="">
              <strong>{{ insuranceFinalDate | formatDate }}</strong>
            </td>
          </tr>
          <tr>
            <td class="">Número de pessoas</td>
            <td class="">
              <strong>{{ insurancePeopleQuantity }}</strong>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
    name: "ResumeBox",
    computed: {
      chargePrice () {
      if (this.insurancePlan == 'MOVE') {
        return this.moveFinalPrice
      }else{
        return this.movePlusFinalPrice
      }

    },
    moveFinalPrice() {
      let price = this.insurancePrice.totalValor
      price += parseFloat((this.insuranceCivilResponsability === true) ? this.insurancePrice.totalApRc : 0);
      price += parseFloat((this.insuranceCovid === true) ? this.insurancePrice.totalCovid : 0);
      return price.toFixed(2)
    },
    movePlusFinalPrice() {
      let price = this.insurancePrice.totalValorVip
      price += parseFloat((this.insuranceCivilResponsability === true) ? this.insurancePrice.totalApRcVip : 0);
      price += parseFloat((this.insuranceCovid === true) ? this.insurancePrice.totalCovidVip : 0);
      return price.toFixed(2)
    },
    insurancePersons:{
      get () {
        return this.$store.state.insurance.person
      },
    },
    insuranceId: {
      get () {
        return this.$store.state.insurance.id
      },
    },
    insuranceCountry: {
      get () {
        return this.$store.state.insurance.country
      }
    },
    paises() {
      return (this.$store.state.insurance.country).join(', ');
    },
    insuranceInitialDate: {
      get () {
        return this.$store.state.insurance.initialDate
      }
    },
    insuranceFinalDate: {
      get () {
        return this.$store.state.insurance.finalDate
      }
    },
    insurancePeopleQuantity: {
      get () {
        return this.$store.state.insurance.peopleQuantity
      }
    },
    insurancePlan: {
      get () {
        return this.$store.state.insurance.plan
      }
    },
    insurancePrice: {
      get () {
        return this.$store.state.insurance.price
      }
    },
    insuranceCivilResponsability: {
      get () {
        return this.$store.state.insurance.civilResponsability
      }
    },
    insurancePersonalAccidents: {
      get () {
        return this.$store.state.insurance.personalAccidents
      }
    },
    insuranceCovid: {
      get () {
        return this.$store.state.insurance.covid
      }
    },
    insurancePersonName: {
      get () {
        return this.$store.state.insurance.person.name
      },
      set (value) {
        this.$store.commit('setInsurancePersonName', {insurancePersonName: value})
      }
    },
    insurancePersonSurname: {
      get () {
        return this.$store.state.insurance.person.surname
      },
      set (value) {
        this.$store.commit('setInsurancePersonSurname', {insurancePersonSurname: value})
      }
    },
    insuranceEmail: {
      get () {
        return this.$store.state.insurance.email
      },
      set (value) {
        this.$store.commit('setInsuranceEmail', {email: value})
      }
    },
    insurancePersonBirthday: {
      get () {
        return this.$store.state.insurance.person.birthday
      },
      set (value) {
        this.$store.commit('setInsurancePersonBirthday', {insurancePersonBirthday: value})
      }
    },
    insurancePersonMobileNumber: {
      get () {
        return this.$store.state.insurance.person.mobileNumber
      },
      set (value) {
        this.$store.commit('setInsurancePersonMobileNumber', {insurancePersonMobileNumber: value})
      }
    },
    insuranceAddressLine1: {
      get () {
        return this.$store.state.insurance.address.line1
      },
      set (value) {
        this.$store.commit('setInsuranceAddressLine1', {insuranceAddressLine1: value})
      }
    },
    insuranceAddressLine2: {
      get () {
        return this.$store.state.insurance.address.line2
      },
      set (value) {
        this.$store.commit('setInsuranceAddressLine2', {insuranceAddressLine2: value})
      }
    },
    insuranceAddressCity: {
      get () {
        return this.$store.state.insurance.address.city
      },
      set (value) {
        this.$store.commit('setInsuranceAddressCity', {insuranceAddressCity: value})
      }
    },
    insuranceAddressLocation: {
      get () {
        return this.$store.state.insurance.address.location
      },
      set (value) {
        this.$store.commit('setInsuranceAddressLocation', {insuranceAddressLocation: value})
      }
    },
    insuranceAddressPostalCode: {
      get () {
        return this.$store.state.insurance.address.postalCode
      },
      set (value) {
        this.$store.commit('setInsuranceAddressPostalCode', {insuranceAddressPostalCode: value})
      }
    },
    insuranceAddressCountryOfAddress: {
      get () {
        return this.$store.state.insurance.address.countryOfAddress
      },
      set (value) {
        this.$store.commit('setInsuranceAddressCountryOfAddress', {insuranceAddressCountryOfAddress: value})
      }
    },
    insuranceTerms: {
      get () {
        return this.$store.state.insurance.terms
      },
      set (value) {
        this.$store.commit('setInsuranceTerm', {insuranceTerms: value})
      }
    },
    easyPayUrl: {
      get () {
        return this.$store.state.insurance.easyPayUrl
      },
      set (value) {
        this.$store.commit('setEasyPayUrl', {easyPayUrl: value})
      }
    },
    easyPayEntity: {
      get () {
        return this.$store.state.insurance.easyPayEntity
      },
      set (value) {
        this.$store.commit('setEasyPayEntity', {easyPayEntity: value})
      }
    },
    easyPayReference: {
      get () {
        return this.$store.state.insurance.easyPayReference
      },
      set (value) {
        this.$store.commit('setEasyPayReference', {easyPayReference: value})
      }
    },
    easyPayPrice: {
      get () {
        return this.$store.state.insurance.easyPayPrice
      },
      set (value) {
        this.$store.commit('setEasyPayPrice', {easyPayPrice: value})
      }
    },
    easyPayExpirationTime: {
      get () {
        return this.$store.state.insurance.easyPayExpirationTime
      },
      set (value) {
        this.$store.commit('setEasyPayExpirationTime', {easyPayExpirationTime: value})
      }
    },
    insuranceOrderCode: {
      get () {
        return this.$store.state.insurance.orderCode
      },
      set (value) {
        this.$store.commit('setInsuranceOrderCode', {insuranceOrderCode: value})
      }
    },
    promotional: {
      get() {
        return this.$store.state.promotional;
      },
      set(promotional) {
        this.$store.commit("setPromotional", promotional);
      },
    }
    }
}
</script>

<style scoped>

</style>